import React, { useCallback, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Bottleneck from "bottleneck";
import { useAuth } from "../hooks/useAuth";
import { servicesClient } from "../services/servicesApi";
import { MascotDataHeader } from "../components/MascotDataHeader";
import { ListDataFile } from "../components/ListDataFile";
import DragAndDrop from "../ui/DragAndDrop";
import FileInput from "../ui/FileInput";
import { useTasks } from "../hooks/useTasks";
import { useEditMascot } from "../hooks/useEditMascot";
import IconUpload from "../images/icons/upload.svg";

export default function EditMascotDataFile() {
  const { currentUser, uploadInfo } = useAuth();
  const { mascotId } = useParams();
  const { embeddingTask, addTask, taskList, handleFailedUpload } = useTasks();
  const { mascot } = useEditMascot();
  const dndRef = useRef();

  const limiter = useMemo(() => new Bottleneck({ maxConcurrent: 2 }), []);

  const uploadFile = useCallback(
    async (file) => {
      if (!currentUser) return;
      const result = await servicesClient.dataAddFile(mascotId, file, currentUser._id);
      if (!result.ok || result.data.error) {
        handleFailedUpload(file);
        console.log("Upload Error! " + result.originalError || result.data.error);
        toast.error(() => <><b>{file.name}</b>: {String(result.originalError || result.data.error)}.</>);
        return;
      }
      addTask(result.data);
    },
    // eslint-disable-next-line
    [mascotId, currentUser, addTask, taskList]
  );

  const fileHandler = useCallback(
    (files) => {
      if (files.length === 0) return;
      const supportedFiles = Array.from(files).filter((file) => {
        if (
          mascot.data?.uploads.filter((doc) => doc.type === "file" && !doc.nonTrainingFileType).some((u) => u.name === file.name) ||
          taskList
            .filter((doc) => doc.type === "addFileTask" && doc.status === "queued")
            .some((u) => u?.meta?.filename === file.name)
        ) {
          toast.error(() => <><b>{file.name}</b>: Duplicate files cannot be uploaded.</>);
          return false;
        }
        if (
          uploadInfo.supportedFormats &&
          !uploadInfo.supportedFormats.some((ext) => file.name.toLocaleLowerCase().endsWith(ext))
        ) {
          toast.error(() => <><b>{file.name}</b>: File extension not supported.</>);
          return false;
        }
        if (uploadInfo.maxContentLength && uploadInfo.maxContentLength < file.size) {
          const maxSizeMb = (uploadInfo.maxContentLength / (1024 * 1024)).toFixed(2);
          toast.error(() => <><b>{file.name}</b>: File size limit exceeded ({maxSizeMb} MB).</>);
          return false;
        }
        return true;
      });
      if (supportedFiles.length === 0) return;

      // Start uploading
      const uploadPromises = supportedFiles
        .sort(() => 0.5 - Math.random())
        .map((file) =>
          limiter.schedule(() => {
            addTask({
              meta: {
                filename: file.name,
              },
              type: "uploadFileTask",
              status: "queued",
              id: "file_" + file.name,
            });
            uploadFile(file);
          })
        );

      Promise.allSettled(uploadPromises).finally(() => {
        // All uploads completed
        dndRef.current && dndRef.current?.reset();
      });
    },
    // eslint-disable-next-line
    [mascot, uploadInfo, limiter, uploadFile]
  );

  return (
    <div>
      <MascotDataHeader></MascotDataHeader>

      <div className="data-pane full-width">
        <h3>
          <img src={IconUpload} alt={IconUpload}></img> File Upload
        </h3>

        {mascot &&
          mascot.data &&
          mascot.data?.uploads &&
          mascot.data?.uploads.filter((doc) => doc.type === "file").length === 0 && (
            <DragAndDrop ref={dndRef} disabled={!!embeddingTask} fileHandler={fileHandler}></DragAndDrop>
          )}

        {mascot &&
          mascot.data &&
          mascot.data?.uploads &&
          mascot.data?.uploads.filter((doc) => doc.type === "file").length > 0 && (
            <ListDataFile
              title="Uploaded Files"
              searchLabel="Search uploaded files"
              disabled={!!embeddingTask}
              buttonBar={
                <FileInput
                  disabled={!!embeddingTask}
                  className="small"
                  label="Browse Files"
                  onChange={(e) => fileHandler(e.target.files)}
                  multiple={true}
                ></FileInput>
              }
              files={
                mascot.data &&
                mascot.data?.uploads &&
                mascot.data?.uploads
                  .filter((doc) => {
                    return doc.type === "file" && !doc.nonTrainingFileType;
                  })
                  .map((doc) => {
                    doc.editable =
                      !doc.name.endsWith(".xlsx") &&
                      !doc.name.endsWith(".ods") &&
                      !doc.name.endsWith(".csv") &&
                      !doc.name.endsWith(".tsv");
                    return doc;
                  })
                  .reverse()
              }
            ></ListDataFile>
          )}
      </div>
    </div>
  );
}
