import React, { useContext, createContext } from "react";

const extensionContext = createContext();
const editorExtensionId = process.env.REACT_APP_CHROME_EXTENSION_ID;

function useProvideExtension() {
  const sendMessageExtension = async (action, data) => {
    const promise = new Promise((resolve, reject) => {
      if (window.chrome && window.chrome.runtime) {
        let request = {
          action,
          data,
        };
        window.chrome.runtime.sendMessage(editorExtensionId, request, function (response) {
          if (window.chrome.runtime.lastError) {
            if (window.chrome.runtime.lastError.message === "Could not establish connection. Receiving end does not exist.") {
              reject({ result: "KO", error: "Extension is not installed or not running" });
            } else {
              reject({ result: "KO", error: window.chrome.runtime.lastError.message });
            }
          } else if (!response) {
            reject({ result: "KO", error: "Please install extension" });
          } else {
            if (response.result === "KO") {
              reject(response);
            } else {
              if (response.message) {
                alert(response.message);
              }
              resolve(response.data);
            }
          }
        });
      } else {
        reject({ result: "KO", error: "Please install extension on Chrome" });
      }
    });

    return promise;
  };

  // Return the user object and extension methods
  return {
    sendMessageExtension,
  };
}

export function ProvideExtension({ children }) {
  const extension = useProvideExtension();
  return <extensionContext.Provider value={extension}>{children}</extensionContext.Provider>;
}

export const useExtension = () => {
  return useContext(extensionContext);
};
