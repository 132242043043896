import { useState, useEffect, useRef } from "react";

export const useIntersect = ({ root = null, rootMargin = "0px", threshold = 1.0, onIntersect }) => {
  const [node, setNode] = useState(null);
  const observer = useRef(null);

  useEffect(() => {
    if (observer.current) observer.current?.disconnect();

    if ('IntersectionObserver' in window) {
      observer.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            onIntersect(entry);
          }
        },
        {
          root,
          rootMargin,
          threshold,
        }
      );
    }

    const { current: currentObserver } = observer;

    if (node) currentObserver?.observe(node);

    return () => currentObserver?.disconnect();
  }, [node, root, rootMargin, threshold, onIntersect]);

  return [setNode];
};
