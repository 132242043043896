import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTasks } from "../hooks/useTasks";
import Popover from "./Popover";
import CheckBox from "../ui/CheckBox.js";
import { useEditMascot } from "../hooks/useEditMascot";
import { FileIcon } from "../components/FileIcon";

export function ListDataFile({
  searchLabel = "Search files",
  files = [],
  nameField = "name",
  showDeleteButton = true,
  buttonBar,
  getURLAction,
  getFileAction,
  getFileActionPermanent,
  showFilterType = true,
  disabled,
  getFileIcon = FileIcon,
  mimeLabelMap = {},
  docsToUpdate,
  handleRefreshDoc,
}) {
  const [filter, setFilter] = useState("");
  const [filterType, setFilterType] = useState({});
  const [sort, setSort] = useState("-lastModified");
  const history = useHistory();
  const { mascotId } = useParams();
  const { embeddingTask } = useTasks();
  const { handleDeleteUpload } = useEditMascot();
  const [selectedFiles, setSelectedFiles] = useState({});
  const [showFilterPopover, setShowFilterPopover] = useState();

  const setSelectedFile = (docId, value) => {
    if (value) {
      selectedFiles[docId] = value;
    } else {
      delete selectedFiles[docId];
    }
    setSelectedFiles({ ...selectedFiles });
  };

  const deleteFiles = () => {
    Object.keys(selectedFiles).forEach((docId) => {
      handleDeleteUpload(docId);
    });
  };

  const onlyUnique = (value, index, array) => {
    return array.indexOf(value) === index;
  };

  const toggleFilterType = (type, value) => {
    if (value) {
      filterType[type] = true;
    } else {
      delete filterType[type];
    }

    setFilterType({ ...filterType });
  };

  const selectAll = () => {
    let selected = [];
    for (let f of files) {
      selected[f._id] = true;
    }
    setSelectedFiles(selected);
  };

  const updateAll = () => {
    let filesList = docsToUpdate(files);
    filesList.forEach((doc) => {
      handleRefreshDoc(doc);
    });
  };

  const updateSelected = () => {
    docsToUpdate(files).forEach((doc) => {
      if (selectedFiles[doc._id]) handleRefreshDoc(doc);
    });
    setSelectedFiles([]);
  };

  return (
    <div className="data-list">
      <div className="input-combo over">
        <input
          type="text"
          className="small"
          placeholder={searchLabel}
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
        />

        <div className="icon-btn" onClick={() => setShowFilterPopover(true)}>
          <i className="icon-filter"></i>

          {showFilterPopover && (
            <Popover className="doc-filter-options right" close={() => setShowFilterPopover(false)}>
              <div className="select xsmall">
                <select onChange={(e) => setSort(e.target.value)}>
                  <option value="-lastModified">Recently Added</option>
                  <option value="lastModified">Oldest</option>
                </select>
              </div>

              {showFilterType && (
                <>
                  <span className="meta small">Select File types</span>

                  {files
                    .map((f) => (f.name.match(/\.[a-zA-Z0-9]{1,4}$/i) ? f.name.split(".").pop() : f.mimeType))
                    .filter(onlyUnique)
                    .map((type, i) => (
                      <div className="checkbox-wrapper" key={i}>
                        <CheckBox
                          className="small"
                          checked={filterType[type]}
                          onChange={(e) => toggleFilterType(type, e.checked)}
                        />
                        <label>{mimeLabelMap[type] || type}</label>
                      </div>
                    ))}
                </>
              )}
            </Popover>
          )}
        </div>
      </div>

      <div className="file-list-btn-bar">
        {buttonBar && buttonBar}

        {!embeddingTask && docsToUpdate && docsToUpdate(files).length > 0 && (
          <>
            {Object.keys(selectedFiles).length === 0 ? (
              <button className="small" onClick={() => updateAll()}>
                <span>Update All</span>
              </button>
            ) : (
              <button className="small" onClick={() => updateSelected()}>
                <span>Update Selected</span>
              </button>
            )}
          </>
        )}

        {Object.keys(selectedFiles).length > 0 && (
          <button className="small danger" onClick={deleteFiles} disabled={disabled}>
            Delete Files
          </button>
        )}
      </div>

      <div className="select-all">
        <span className="meta" onClick={() => selectAll()}>
          Select All
        </span>
        <span className="meta" onClick={() => setSelectedFiles([])}>
          Deselect All
        </span>
      </div>

      <div className="list">
        <ul>
          {files
            .filter((doc) => {
              if (filter) {
                if (!doc.name.toLowerCase().includes(filter.toLocaleLowerCase())) return false;
              }

              if (Object.keys(filterType).length > 0) {
                let fileType = doc.name.includes(".") ? doc.name.split(".").pop() : doc.mimeType;
                if (!Object.keys(filterType).includes(fileType)) {
                  return false;
                }
              }
              return true;
            })

            .sort((a, b) => {
              if (sort === "lastModified") return a.lastModified > b.lastModified ? 1 : -1;
              return a.lastModified > b.lastModified ? -1 : 1;
            })
            .map((doc) => {
              return (
                <li className="list-item clickable" key={doc._id} title={doc.name}>
                  <div className="checkbox-wrapper">
                    <CheckBox checked={!!selectedFiles[doc._id]} onChange={(e) => setSelectedFile(doc._id, e.checked)} />
                  </div>

                  {getFileIcon({ doc })}

                  <div className="list-item-name">
                    <span onClick={() => history.push(`/mascot/${mascotId}/edit/data/document/${doc._id}`)}>
                      {doc[nameField]}
                    </span>

                    {getURLAction && getURLAction(doc)}
                  </div>

                  <div className="list-item-meta">{getFileActionPermanent && getFileActionPermanent(doc)}</div>

                  <div className="list-item-actions">
                    {doc.editable && !embeddingTask ? (
                      <i
                        className="icon-edit"
                        onClick={() => history.push(`/mascot/${mascotId}/edit/data/document/${doc._id}#editing`)}
                      ></i>
                    ) : (
                      <></>
                    )}

                    {getFileAction && getFileAction(doc)}

                    {showDeleteButton && !embeddingTask && (
                      <i className="icon-remove" onClick={() => handleDeleteUpload(doc._id)}></i>
                    )}
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
