import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";
import { isMobile, isChrome, isEdge } from "react-device-detect";
import GoogleChrome from "../images/icons/google-chrome.svg";
import MsEdge from "../images/icons/ms-edge.svg";

// Components
import MascotImage from "./MascotImage";
import Modal from "./Modal";
import Loader from "./Loader";
import Popover from "./Popover";

// Context
import { useAuth } from "../hooks/useAuth";
import { useConversations } from "../hooks/useConversations";
import { useIntersect } from "../hooks/useIntersect";
import { useMascot } from "../hooks/useMascot";
import { useExtension } from "../hooks/useExtension";

export default function MascotSidebar({ mascotInit, editing, loading, setSidebarOpen }) {
  const history = useHistory();
  // Context
  const { mascot } = useMascot();
  const { sendMessageExtension } = useExtension();
  const { conversations, selectedConversation, selectConversation, createConversation, loadMoreConversation } =
    useConversations();
  const { isAuthenticated, currentOrganization } = useAuth();

  // Utils
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [sharePopoverOpen, setSharePopoverOpen] = useState(false);
  const [extensionModalOpen, setExtensionModalOpen] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [lazyLoader] = useIntersect({ rootMargin: "0px", threshold: 1.0, onIntersect: handleIntersect });

  // Conversations
  const [today, setToday] = useState([]);
  const [last7days, setLast7days] = useState([]);
  const [last30days, setLast30days] = useState([]);
  const [others, setOthers] = useState([]);
  const [, setPage] = useState(1);

  function handleIntersect(target) {
    if (target.isIntersecting && loadMore) {
      setLoadMore(false);
      setPage((prevPage) => {
        let nextPage = prevPage + 1;
        loadMoreConversation(nextPage).then((nextList) => {
          if (nextList?.length > 0) {
            setLoadMore(true);
          }
        });
        return nextPage;
      });
    }
  }

  useEffect(() => {
    if (conversations.length === 0) return;
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const sevenDaysAgo = new Date(todayDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const thirtyDaysAgo = new Date(todayDate);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    const todaySessions = [];
    const last7daysSessions = [];
    const last30daysSessions = [];
    const otherSessions = [];

    conversations
      .map((c) => ({ ...c, lastPrompt: new Date(c.lastPrompt) }))
      .sort((c1, c2) => c2.lastPrompt - c1.lastPrompt)
      .forEach((conversation) => {
        if (conversation.lastPrompt >= todayDate) {
          todaySessions.push(conversation);
        } else if (conversation.lastPrompt >= sevenDaysAgo) {
          last7daysSessions.push(conversation);
        } else if (conversation.lastPrompt >= thirtyDaysAgo) {
          last30daysSessions.push(conversation);
        } else {
          otherSessions.push(conversation);
        }
      });

    setToday(todaySessions);
    setLast7days(last7daysSessions);
    setLast30days(last30daysSessions);
    setOthers(otherSessions);
  }, [conversations]);

  const ConversationListItem = ({ conversation }) => {
    return (
      <div
        className={classNames(
          "sessions-list-item",
          loading && "disabled",
          selectedConversation._id === conversation._id && "active"
        )}
        onClick={() => handleSelectConversation(conversation)}
      >
        <span className="session-title" title={conversation.title}>
          {conversation.title}
        </span>
        {selectedConversation._id === conversation._id && <span className="session-active"></span>}
      </div>
    );
  };

  const connectExtension = async () => {
    try {
      await sendMessageExtension("addMascot", { mascot });
      alert("Mascot added to extension");
    } catch (e) {
      console.error(e);
      alert("Error: " + e.error);
    }
  };

  const handleCreateConversation = () => {
    createConversation();
    setSidebarOpen(false);
  }

  const handleSelectConversation = (conversation) => {
    selectConversation(conversation);
    setSidebarOpen(false);
  }

  return (
    <div className={classNames("mascot-sidebar", !mascot.image && "bg-empty")}>
      <div className="mascot-sidebar-content">
        {!editing && isAuthenticated && mascot.ownMascot && (
          <Link to="/" className={classNames("icon-btn to-home", loading && "disabled")}>
            <i className="icon-chevron-left"></i>
          </Link>
        )}

        {mascot.public ? (
          (!isMobile && (isChrome || isEdge)) ? (
            <div className={classNames("icon-btn share", !mascot.published && "disabled")} onClick={() => setSharePopoverOpen(true)}>
              <i className="icon-share"></i>

              {sharePopoverOpen && 
                <Popover className="top right share-popover" close={() => setSharePopoverOpen(false)}>
                  <div className="popover-item" onClick={() => setShareModalOpen(true)}>
                    <i className="icon-share"></i> Share Mascot
                  </div>

                  <div className="popover-item browser-ext" onClick={() => setExtensionModalOpen(true)}>
                    <img
                      src={isEdge ? MsEdge : GoogleChrome}
                      alt={isEdge ? "Edge" : "Chrome"}
                    />
                    {isEdge ? "Add to Edge" : "Add to Chrome"}
                  </div>
                </Popover>
              }
            </div>
          ) : (
            <div className={classNames("icon-btn share", !mascot.published && "disabled")} onClick={() => setShareModalOpen(true)}>
              <i className="icon-share"></i>
            </div>
          )
        ) : (!isMobile && (isChrome || isEdge)) && (
          <div className="icon-btn browser-ext" onClick={() => setExtensionModalOpen(true)}>
            <img
              src={isEdge ? MsEdge : GoogleChrome}
              alt={isEdge ? "Add to Edge" : "Add to Chrome"}
            />
          </div>
        )}

        <div className="mascot-header">
          <div className="mascot-image-wrapper">
            {loading && (
              <div className="thinking">
                <Loader></Loader>
              </div>
            )}
            <MascotImage mascot={mascot._id ? mascot : mascotInit}></MascotImage>
          </div>

          {mascot.name && (
            <div className="mascot-heading">
              <h2>{mascot.name}</h2>
              <p>{mascot.description}</p>
            </div>
          )}

          {mascotInit && (
            <div className="mascot-heading">
              <h2>{mascotInit.name}</h2>
              <p>{mascotInit.description}</p>
            </div>
          )}
        </div>

        <div className="mascot-actions">
          <div className="admin-actions">
            {isAuthenticated && mascot.ownMascot && mascot._id && (
              <span
                className={classNames("mascot-action", loading && "disabled")}
                onClick={() => history.push(`/mascot/${mascot._id}/edit/info`)}
              >
                <i className="icon-edit"></i> Edit
              </span>
            )}

            {isAuthenticated &&
              mascot.ownMascot &&
              mascot._id &&
              currentOrganization.features &&
              currentOrganization.features.insights && (
                <span
                  className={classNames("mascot-action", loading && "disabled")}
                  onClick={() => history.push(`/mascot/${mascot._id}/insights/overview`)}
                >
                  <i className="icon-graph"></i> Insights
                </span>
              )}
          </div>

          {mascot._id && (
            <span
              className={classNames(
                "mascot-action new-chat",
                (loading || (selectedConversation && selectedConversation._id === "new") || !mascot.published) && "disabled"
              )}
              style={{ backgroundColor: mascot.theme && mascot.theme.brandColor }}
              onClick={() => handleCreateConversation()}
            >
              <i className="icon-new-chat"></i> New Chat
            </span>
          )}
        </div>

        {!mascotInit && (
          <>
            {!mascot.published || !conversations ? (
              <div className="sessions-container">
                <div className="sessions-list-item empty">
                  <span className={conversations.length === 0 ? "loading" : undefined} style={{ width: "74%" }}></span>
                </div>
                <div className="sessions-list-item empty">
                  <span className={conversations.length === 0 ? "loading" : undefined} style={{ width: "54%" }}></span>
                </div>
                <div className="sessions-list-item empty">
                  <span className={conversations.length === 0 ? "loading" : undefined} style={{ width: "94%" }}></span>
                </div>
                <div className="sessions-list-item empty">
                  <span className={conversations.length === 0 ? "loading" : undefined} style={{ width: "82%" }}></span>
                </div>
              </div>
            ) : (
              <div className="sessions-container">
                {today.length > 0 && <div className="sessions-list-separator">Today</div>}
                {today.map((conversation, i) => (
                  <ConversationListItem conversation={conversation} key={i} />
                ))}
                {last7days.length > 0 && <div className="sessions-list-separator">Last 7 Days</div>}
                {last7days.map((conversation, i) => (
                  <ConversationListItem conversation={conversation} key={i} />
                ))}
                {last30days.length > 0 && <div className="sessions-list-separator">Last 30 Days</div>}
                {last30days.map((conversation, i) => (
                  <ConversationListItem conversation={conversation} key={i} />
                ))}
                {others.length > 0 && <div className="sessions-list-separator">Older</div>}
                {others.map((conversation, i) => (
                  <ConversationListItem conversation={conversation} key={i} />
                ))}
                <div ref={lazyLoader} />
              </div>
            )}
          </>
        )}

        {mascot._id && (
          <>
            <Modal title="Share Mascot" size="small" isOpen={shareModalOpen} close={() => setShareModalOpen(false)}>
              <div className="input-group">
                <label>Direct link to mascot</label>
                <input type="text" readOnly={true} value={`${window.location.origin}/mascot/${mascot._id}`}></input>
              </div>

              <button
                onClick={() => {
                  toast.promise(navigator.clipboard.writeText(`${window.location.origin}/mascot/${mascot._id}`), {
                    success: "Copied to clipboard",
                    error: "Error copying to clipboard",
                  });
                }}
              >
                Copy Link
              </button>
            </Modal>

            <Modal 
              title={
                <>
                  <img
                    src={isEdge ? MsEdge : GoogleChrome}
                    alt={isEdge ? "Edge" : "Chrome"}
                    style={{ width: "24px", height: "24px", marginRight: "8px" }}
                  />
                  {isEdge ? "Add to Edge" : "Add to Chrome"}
                </>
              }
              size="small"
              isOpen={extensionModalOpen}
              close={() => setExtensionModalOpen(false)}
            >
              {isEdge ? (
                <>
                  <p>
                    To add this mascot to Edge, you will need to allow extensions from other stores.{" "}
                    Go to "Manage Extensions" and enable "Allow extensions from other stores".{" "}
                    <a href="https://support.microsoft.com/en-us/microsoft-edge/add-turn-off-or-remove-extensions-in-microsoft-edge-9c0ec68c-2fbc-2f2c-9ff0-bdc76f46b026" target="_blank" rel="noopener noreferrer">Learn more</a>
                  </p>
                  <p>
                    Next, install the extension from the <a href="https://chromewebstore.google.com/detail/wiseox-for-chrome-use-ai/ppikglnnfgkhnknbhcdkeclgoiefccpd" target="_blank" rel="noopener noreferrer">Chrome Web Store</a>, then click below to add the mascot to Edge.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    To add this mascot to Chrome, first install the extension from the <a href="https://chromewebstore.google.com/detail/wiseox-for-chrome-use-ai/ppikglnnfgkhnknbhcdkeclgoiefccpd" target="_blank" rel="noopener noreferrer">Chrome Web Store</a>.
                  </p>
                  <p>
                    Then click below to add the mascot to Chrome.
                  </p>
                </>
              )}

              <button onClick={connectExtension}>
                {isEdge ? "Add mascot to Edge" : "Add mascot to Chrome"}
              </button>
            </Modal>
          </>
        )}
      </div>
      <div className="mascot-sidebar-bg-wrapper">
        <div
          className="mascot-sidebar-bg"
          style={
            (mascot.image || mascotInit) && {
              backgroundImage: `url(${mascot.image || mascotInit.image})`,
            }
          }
        ></div>
      </div>
    </div>
  );
}
