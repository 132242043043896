import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

export default function EmbedChat({ mascotId, label = "Ask WiseOX", iconImage, labelColor }) {
  const iframeURL = (process.env.REACT_APP_URL_EMBED || process.env.REACT_APP_URL_APP) + "/mascot/" + mascotId + "/chat";

  const iframeRef = useRef();
  const [responding, setResponding] = useState(false);
  const [showIFrame, setShowIFrame] = useState(false);
  const [mascot, setMascot] = useState();

  const handleSendMessage = (message) => {
    if (message !== undefined && iframeRef.current) {
      // Send prompt to iframe

      iframeRef.current?.contentWindow.postMessage(
        {
          type: "send-message",
          message: message,
        },
        "*"
      );
    }
  };

  // Receive Response
  useEffect(() => {
    // Import CSS
    var link = document.createElement("link");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute(
      "href",
      window.OVERRIDE_CSS_EMBED_URL
        ? window.OVERRIDE_CSS_EMBED_URL
        : process.env.REACT_APP_URL_APP + "/static/css/embed-wiseox.css"
    );
    document.head.appendChild(link);

    // Load mascot
    if (!mascotId) {
      console.error("Specify 'mascot-id' attribute for WiseOx in the 'wiseox-chat' tag");
    } else {
      window.addEventListener("message", (e) => {
        if (e?.data?.type === "received-message") {
          if (e.data.mascotId === mascotId) {
            // Receive the response
            setResponding(false);
          }
        } else if (e?.data?.type === "wiseox-mascot-ready") {
          setMascot(e.data.mascot);
        } else if (e?.data?.type === "send-message-embed") {
          if (!e.data.mascotId || e.data.mascotId === mascotId) {
            setResponding(true);
            handleSendMessage(e.data.message);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="wiseox-embed-chat">
      <div className={classNames("wiseox-embed-img-wrapper", showIFrame && "wiseox-embed-img-hidden")}>
        <div className="wiseox-embed-img" onClick={() => setShowIFrame(true)}>
          <img
            src={
              iconImage
                ? iconImage
                : !mascot
                ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                : mascot.image
            }
            className={responding ? "responding" : ""}
            alt="Mascot Avatar"
          />

          <span className="wiseox-embed-img-label" style={{ backgroundColor: labelColor }}>
            {responding ? "Responding..." : label}
          </span>
          
          {responding && <span className="loader dark"></span>}
        </div>

        <div className="wiseox-btn-close" onClick={() => setShowIFrame(false)}>
          <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Icons/close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon id="icon-close" fill="#FFFFFF" fillRule="nonzero" points="10 8.50017678 3.91403678 2.41421356 2.41421356 3.91403678 8.50017678 10 2.41421356 16.0859632 3.91403678 17.5857864 10 11.4998232 16.0859632 17.5857864 17.5857864 16.0859632 11.4998232 10 17.5857864 3.91403678 16.0859632 2.41421356"></polygon>
            </g>
          </svg>
        </div>
      </div>

      <div className={classNames("wiseox-embed-iframe", !showIFrame && "wiseox-embed-iframe-hidden")}>
        {mascotId && <iframe src={iframeURL} title="WiseOx Chat" ref={iframeRef} id="wiseox-iframe" allow="clipboard-write"></iframe>}
      </div>
    </div>
  );
}
