import React, { useRef, useCallback, useState } from "react";
import { MascotDataHeader } from "../components/MascotDataHeader";
import { toast } from "react-toastify";
import { servicesClient } from "../services/servicesApi";
import { useAuth } from "../hooks/useAuth";
import { ListDataFile } from "../components/ListDataFile";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import { useTasks } from "../hooks/useTasks";
import { useEditMascot } from "../hooks/useEditMascot";
import { useHistory, useLocation } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import OneDriveFilePicker from "../components/OneDriveFilePicker";
import IconOneDrive from "../images/icons/one-drive.svg";

export default function EditMascotDataOneDrive() {
  const { currentOrganization, currentUser } = useAuth();
  const { embeddingTask, addTask, taskList, isUpdating } = useTasks();
  const { mascot, updateDataLastModified } = useEditMascot();
  const pickerRef = useRef();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUpdateLoader, setShowUpdateLoader] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const authorizeUser = async () => {
    const result = await servicesClient.authorizeOneDrive(currentOrganization._id, window.location.href);
    if (result.ok) {
      window.location.href = result.data.authUrl;
    }
  };

  const onSelect = useCallback(
    async (files) => {
      const filteredFiles = files.filter((file) => {
        if (
          mascot.data?.uploads.filter((doc) => doc.type === "onedrive").some((u) => u.name === file.name) ||
          taskList
            .filter((doc) => doc.type === "addOneDriveTask" && doc.status === "queued")
            .some((u) => u?.meta?.filename === file.name)
        ) {
          toast.error(() => (
            <>
              <b>{file.name}</b>: Duplicate files cannot be uploaded.
            </>
          ));
          return false;
        }
        return true;
      });
      const result = await servicesClient.dataAddOneDrive(mascot._id, filteredFiles, currentUser._id);
      if (!result.ok || result.data.error) {
        console.log("Upload Error! " + result.originalError);
        toast.error(() => (
          <>
            <b>{filteredFiles[0].id}</b>: {String(result.originalError)}.
          </>
        ));
        return;
      }
      result.data?.forEach((item) => addTask(item));
    },
    [mascot, currentUser, addTask, taskList]
  );

  const browse = async () => {
    const result = await servicesClient.getOneDriveToken(currentOrganization._id);
    const token = result.ok && result.data.accessToken;

    if (!token) {
      if (mascot.data?.uploads.filter((doc) => doc.type === "onedrive").length > 0) {
        setShowConfirmModal(true);
      } else {
        authorizeUser();
      }
    } else if (pickerRef.current) {
      pickerRef.current.launchOneDrivePicker({ ...result.data });
    }
  };

  const checkUpdates = async () => {
    setShowUpdateLoader(true);
    const result = await servicesClient.dataCheckUpdatesOneDrive(mascot._id);
    setShowUpdateLoader(false);
    if (result.ok) {
      for (const [key, value] of Object.entries(result.data)) {
        value === null &&
          toast.error(() => (
            <>
              <b>Cannot update file:</b> "{mascot.data?.uploads.find((u) => u._id === key)?.name}" was deleted from OneDrive.
            </>
          ));
      }
      updateDataLastModified(result.data);
    }
  };

  const handleRefreshDoc = async (upload) => {
    let pendingUpdateTask = isUpdating(upload);
    if (!pendingUpdateTask) {
      const result = await servicesClient.dataUpdateOneDrive(mascot._id, upload._id, currentUser._id);
      result.ok &&
        addTask({ ...result.data, meta: { filename: upload.name, mimeType: upload.mimeType, oneDriveId: upload.oneDriveId } });
    } else {
      addTask({
        status: "failed",
        type: "onedrive",
        meta: {
          error: "Update already in progress",
          filename: pendingUpdateTask.meta.filename,
          mimeType: pendingUpdateTask.meta.mimeType,
        },
      });
    }
  };

  const getFileAction = (doc) => (
    <>
      {!embeddingTask && doc.lastModified > doc.lastUpdate && (
        <i
          className="icon-reload"
          onClick={() => {
            handleRefreshDoc(doc);
          }}
        ></i>
      )}
    </>
  );

  const getFileActionPermanent = (doc) => (
    <>
      {!embeddingTask && doc.lastModified > doc.lastUpdate && doc.oneDriveAccount === currentOrganization.oneDriveConnected && (
        <span className="meta small warning">This file has changes, please update</span>
      )}
      {!embeddingTask && doc.oneDriveAccount !== currentOrganization.oneDriveConnected && (
        <span className="meta small">Not synced (account changed)</span>
      )}
    </>
  );

  const docsToUpdate = (docs) => {
    return docs.filter(
      (doc) =>
        doc.type === "onedrive" &&
        doc.lastModified > doc.lastUpdate &&
        doc.oneDriveAccount === currentOrganization.oneDriveConnected
    );
  };

  return (
    <div>
      <MascotDataHeader></MascotDataHeader>

      <div className="data-pane full-width">
        {!currentOrganization.oneDriveConnected ? (
          <>
            <h3>
              <img src={IconOneDrive} alt={IconOneDrive}></img> Connect Microsoft OneDrive
            </h3>

            <p>Connect your Microsoft account to sync files from OneDrive to your mascot.</p>
          </>
        ) : (
          <>
            <h3>
              <img src={IconOneDrive} alt={IconOneDrive}></img> OneDrive Files
            </h3>
          </>
        )}

        {mascot.data && mascot.data?.uploads && mascot.data?.uploads.filter((doc) => doc.type === "onedrive").length > 0 ? (
          <ListDataFile
            title="Uploaded Files"
            searchLabel="Search files"
            getFileAction={getFileAction}
            getFileActionPermanent={getFileActionPermanent}
            disabled={!!embeddingTask}
            handleRefreshDoc={handleRefreshDoc}
            docsToUpdate={docsToUpdate}
            buttonBar={
              <>
                <button className="small" onClick={browse} disabled={embeddingTask}>
                  {currentOrganization.oneDriveConnected ? "Browse Files" : "Connect Account"}
                </button>

                {currentOrganization.oneDriveConnected && (
                  <button className="small" disabled={showUpdateLoader} onClick={checkUpdates}>
                    {showUpdateLoader && <Loader classNames="small"></Loader>}
                    <span>Check For Updates</span>
                  </button>
                )}
              </>
            }
            files={mascot.data && mascot.data?.uploads && mascot.data?.uploads.filter((doc) => doc.type === "onedrive").reverse()}
          ></ListDataFile>
        ) : (
          <button className="small" onClick={browse} disabled={embeddingTask}>
            {currentOrganization.oneDriveConnected ? "Browse Files" : "Connect Account"}
          </button>
        )}

        <OneDriveFilePicker ref={pickerRef} onSelect={onSelect} />
        <Modal
          title="Connect to OneDrive"
          size="small"
          isOpen={showConfirmModal}
          close={() => setShowConfirmModal(false)}
          action={
            <>
              <button className="action" onClick={authorizeUser}>
                Continue
              </button>
              <button onClick={() => setShowConfirmModal(false)}>Cancel</button>
            </>
          }
        >
          <p>Existing OneDrive documents will not sync if connecting with a different OneDrive account.</p>
        </Modal>
        <Modal
          title={`Authentication Error ${query.get("error")}`}
          isOpen={query.has("error")}
          close={() => history.push(location.pathname)}
        >
          <p>
            Authentication failed with the following error: <br />
            <code>{query.get("error_description")}</code>
          </p>
        </Modal>
      </div>
    </div>
  );
}
