import apisauce from "apisauce";
//import { setupCache } from 'axios-cache-adapter';

/*const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});*/

export const create = (baseURL = process.env.REACT_APP_URL_SERVICES) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 120000,
    //adapter: cache.adapter,
  });

  // Get documents
  const dataGet = async (mascotId, uploadId, data = {}) => {
    const uploadUrlResult = await api.get(`/mascot/${mascotId}/upload/${uploadId}`, data);
    if (!uploadUrlResult.ok) {
      throw new Error(uploadUrlResult.originalError);
    }
    return api.get(uploadUrlResult.data.uploadUrl);
  };

  // Add attachments
  const dataAddAttachment = async (mascotId, file, userId, conversationId, attachmentType) => {
    return dataAddFile(mascotId, file, userId, conversationId, "chat_attachment", attachmentType);
  };

  const dataRemoveAttachment = async (mascotId, uploadId) => {
    //TODO: Merge with data delete
    console.log("Deleting ", mascotId, uploadId);
    return api.delete(`/mascot/${mascotId}/upload/${uploadId}`);
  };

  // Add documents
  const dataAddFile = async (
    mascotId,
    file,
    userId,
    conversationId = null,
    nonTrainingFileType = null,
    attachmentType = null
  ) => {
    const uploadUrlResult = await api.get(`/mascot/${mascotId}/uploadUrl`, { type: file.type });
    if (!uploadUrlResult.ok) {
      throw new Error(uploadUrlResult.originalError);
    }
    const headers = {
      "Content-Type": file.type,
    };
    const uploadResult = await api.put(uploadUrlResult.data.uploadUrl, file, { headers });
    if (!uploadResult.ok) {
      throw new Error(uploadResult.originalError);
    }

    return api.post(`/mascot/${mascotId}/upload/file`, {
      userId,
      uploadId: uploadUrlResult.data.uploadId,
      filename: file.name,
      ...(conversationId && { conversationId }),
      ...(nonTrainingFileType && { nonTrainingFileType }),
      ...(attachmentType && { attachmentType }),
    });
  };

  const dataAddWeb = (mascotId, url, userId) => {
    return api.post(`/mascot/${mascotId}/upload/web`, { url, userId });
  };

  const dataAddGoogleDrive = (mascotId, files, userId, accessToken) => {
    return api.post(`/mascot/${mascotId}/upload/googleDrive`, { files, userId, accessToken });
  };

  const dataAddConfluence = (mascotId, files, userId) => {
    return api.post(`/mascot/${mascotId}/upload/confluence`, { files, userId });
  };

  const dataAddOneDrive = (mascotId, files, userId) => {
    return api.post(`/mascot/${mascotId}/upload/oneDrive`, { files, userId });
  };

  const dataAddSharepoint = (mascotId, files, userId) => {
    return api.post(`/mascot/${mascotId}/upload/sharepoint`, { files, userId });
  };

  const dataAddSharepointPages = (mascotId, files, userId) => {
    return api.post(`/mascot/${mascotId}/upload/sharepointPages`, { files, userId });
  };

  const duplicateMascot = (mascotId) => {
    return api.post(`/mascot/${mascotId}/duplicateMascot`, {});
  };

  // Web
  const crawlWebSubpath = (mascotId, path) => {
    return api.post(`/mascot/${mascotId}/upload/web/getLinks`, {
      path,
    });
  };

  // Check Docs
  const dataCheckUpdatesGoogleDrive = (mascotId) => {
    return api.get(`/mascot/${mascotId}/upload/googleDrive`);
  };

  const dataCheckUpdatesConfluence = (mascotId) => {
    return api.get(`/mascot/${mascotId}/upload/confluence`);
  };

  const dataCheckUpdatesOneDrive = (mascotId) => {
    return api.get(`/mascot/${mascotId}/upload/oneDrive`);
  };

  const dataCheckUpdatesSharepoint = (mascotId) => {
    return api.get(`/mascot/${mascotId}/upload/sharepoint`);
  };

  const dataCheckUpdatesZendesk = (mascotId) => {
    return api.get(`/mascot/${mascotId}/upload/zendesk`);
  };

  // Edit Docs
  const dataUpdateFile = (mascotId, uploadId, data) => {
    return api.put(`/mascot/${mascotId}/upload/file/${uploadId}`, data);
  };

  const dataUpdateWeb = (mascotId, uploadId, userId) => {
    return api.put(`/mascot/${mascotId}/upload/web/${uploadId}`, { userId });
  };

  const dataUpdateGoogleDrive = (mascotId, uploadId, userId) => {
    return api.put(`/mascot/${mascotId}/upload/googleDrive/${uploadId}`, { userId });
  };

  const dataUpdateConfluence = (mascotId, uploadId, userId) => {
    return api.put(`/mascot/${mascotId}/upload/confluence/${uploadId}`, { userId });
  };

  const dataUpdateOneDrive = (mascotId, uploadId, userId) => {
    return api.put(`/mascot/${mascotId}/upload/oneDrive/${uploadId}`, { userId });
  };

  const dataUpdateSharepoint = (mascotId, uploadId, userId) => {
    return api.put(`/mascot/${mascotId}/upload/sharepoint/${uploadId}`, { userId });
  };

  const dataUpdateZendesk = (mascotId, uploadId, userId) => {
    return api.put(`/mascot/${mascotId}/upload/zendesk/${uploadId}`, { userId });
  };

  // Delete documents
  const dataDelete = (mascotId, uploadId, data = {}) => {
    return api.delete(`/mascot/${mascotId}/upload/${uploadId}`, data);
  };

  // Manage Tasks
  const taskDelete = (mascotId, taskId, data = {}) => {
    return api.delete(`/mascot/${mascotId}/task/${taskId}`, data);
  };

  const taskStop = (mascotId, taskId, data = {}) => {
    return api.post(`/mascot/${mascotId}/task/${taskId}/stop`, data);
  };

  // Manage mascot
  const embeddingGenerate = (mascotId, data = {}) => {
    return api.post(`/mascot/${mascotId}/generateEmbedding`, data);
  };

  const initConversationTitle = (mascotId, conversationId, msg) => {
    return api.put(`/mascot/${mascotId}/initConversationTitle/${conversationId}`, { msg });
  };

  const doneTraining = (mascotId, conversationId, data = {}) => {
    return api.post(`/mascot/${mascotId}/doneTraining/${conversationId}`, data);
  };

  const completion = (mascotId, newPrompt) => {
    return api.post(`/mascot/${mascotId}/completion`, newPrompt);
  };

  const getCurrentEditors = (mascotId) => {
    return api.get(`/mascot/${mascotId}/currentEditors`);
  };

  const uploadInfo = () => {
    return api.get(`/upload-info`);
  };

  // Google Drive
  const getGoogleDriveToken = (organizationId) => {
    return api.get(`/gdrive/getToken/${organizationId}`);
  };

  const authorizeGoogleDrive = (organizationId, next) => {
    return api.get(`/gdrive/authorize/${organizationId}`, { next });
  };

  // Confluence
  const getConfluenceNode = (organizationId, nodeId) => {
    return api.get(`/confluence/${organizationId}/getNode/${nodeId}`);
  };

  const getConfluenceToken = (organizationId) => {
    return api.get(`/confluence/getToken/${organizationId}`);
  };

  const authorizeConfluence = (organizationId, next) => {
    return api.get(`/confluence/authorize/${organizationId}`, { next });
  };

  // OneDrive
  const getOneDriveToken = (organizationId) => {
    return api.get(`/onedrive/getToken/${organizationId}`);
  };

  const authorizeOneDrive = (organizationId, next) => {
    return api.get(`/onedrive/authorize/${organizationId}`, { next });
  };

  // Sharepoint
  const getSharepointScopedToken = (organizationId, scope) => {
    return api.get(`/sharepoint/getScopedToken/${organizationId}`, { scope });
  };

  const getSharePointBaseUrl = (organizationId) => {
    return api.get(`/sharepoint/getBaseUrl/${organizationId}`);
  };

  const authorizeSharepoint = (organizationId, next) => {
    return api.get(`/sharepoint/authorize/${organizationId}`, { next });
  };

  const getSharepointSites = (organizationId) => {
    return api.get(`/sharepoint/${organizationId}/getSites`);
  };

  const getSharepointSitePages = (organizationId, siteId) => {
    return api.get(`/sharepoint/${organizationId}/getSitePages/${siteId}`);
  };

  // Zendesk
  const getZendeskToken = (organizationId) => {
    return api.get(`/zendesk/getToken/${organizationId}`);
  };

  const authorizeZendesk = (organizationId, next, subdomain) => {
    return api.get(`/zendesk/authorize/${organizationId}`, { next, subdomain });
  };

  const getZendeskSections = (organizationId) => {
    return api.get(`/zendesk/${organizationId}/getSections`);
  };

  const getZendeskPosts = (organizationId) => {
    return api.get(`/zendesk/${organizationId}/getPosts`);
  };

  const getZendeskArticles = (organizationId, sectionId) => {
    return api.get(`/zendesk/${organizationId}/getArticles/${sectionId}`);
  };

  const getZendeskArticlesAttachments = (organizationId, articleId) => {
    return api.get(`/zendesk/${organizationId}/getArticlesAttachments/${articleId}`);
  };

  const dataAddZendesk = (mascotId, file, userId) => {
    return api.post(`/mascot/${mascotId}/upload/zendesk`, { file, userId });
  };

  // Insights
  const runSessionstatsAnalysisByConversation = (conversationId) => {
    return api.get(`/run-sessionstats-analysis/${conversationId}`);
  };

  return {
    // Get documents
    dataGet,

    // Chat attachment
    dataAddAttachment,
    dataRemoveAttachment,

    // Add documents
    dataAddFile,
    dataAddWeb,
    dataAddGoogleDrive,
    dataAddConfluence,
    dataAddOneDrive,
    dataAddSharepoint,
    dataAddSharepointPages,
    dataAddZendesk,

    // Web
    crawlWebSubpath,

    // Check Docs
    dataCheckUpdatesGoogleDrive,
    dataCheckUpdatesConfluence,
    dataCheckUpdatesOneDrive,
    dataCheckUpdatesSharepoint,
    dataCheckUpdatesZendesk,

    // Edit Docs
    dataUpdateFile,
    dataUpdateWeb,
    dataUpdateGoogleDrive,
    dataUpdateConfluence,
    dataUpdateOneDrive,
    dataUpdateSharepoint,
    dataUpdateZendesk,

    // Delete documents
    dataDelete,

    // Manage Tasks
    taskDelete,
    taskStop,

    // Manage mascot
    embeddingGenerate,
    initConversationTitle,
    doneTraining,
    completion,
    uploadInfo,
    getCurrentEditors,
    duplicateMascot,

    // Google Drive
    getGoogleDriveToken,
    authorizeGoogleDrive,

    // Confluence
    getConfluenceNode,
    getConfluenceToken,
    authorizeConfluence,

    // Zendesk
    getZendeskToken,
    authorizeZendesk,
    getZendeskSections,
    getZendeskArticles,
    getZendeskPosts,
    getZendeskArticlesAttachments,

    // OneDrive
    getOneDriveToken,
    authorizeOneDrive,

    // Sharepoint
    getSharepointScopedToken,
    getSharePointBaseUrl,
    authorizeSharepoint,
    getSharepointSites,
    getSharepointSitePages,

    // Insights
    runSessionstatsAnalysisByConversation,

    api,
  };
};

export const servicesClient = create();
