import React, { useState, useContext, createContext, useEffect } from "react";
import { useAuth } from "./useAuth";
import { useParams } from "react-router-dom";
import { client } from "../services/api";
import { publish } from "../services/events";
import { useMascot } from "./useMascot";
import { useHistory } from "react-router-dom";

const conversationsContext = createContext();

function useProvideConversations() {
  const { mascotId, conversationId } = useParams();
  const { clientId } = useAuth();
  const { mascot } = useMascot();
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState({});
  const limit = 20;
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      let conversationData = await client.listConversations(mascotId, clientId, 1, limit);
      if (conversationData.ok && conversationData.data) {
        setConversations([...conversationData.data]);
        if (!conversationId) {
          if (conversationData.data.length > 0) {
            selectConversation(conversationData.data[0]);
          } else {
            createConversation();
          }
        }
      }
    };

    if (mascot && mascot._id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [mascot?._id, clientId]);

  const loadMoreConversation = async (page) => {
    let conversationData = await client.listConversations(mascotId, clientId, page, limit);
    if (conversationData.ok) {
      setConversations((conv) => {
        let newList = [...conv, ...conversationData.data];
        return newList;
      });
    }
    return conversationData.data;
  };

  const createConversation = () => {
    let existingConv = conversations.find((c) => c._id === "new");

    if (!existingConv) {
      let newConv = {
        _id: "new",
        title: "New Conversation",
        lastPrompt: new Date().toISOString(),
      };
      setSelectedConversation(newConv);
      setConversations([newConv, ...conversations]);
      publish("changeConversation", newConv);
      return newConv
    } else {
      setSelectedConversation({ ...existingConv });
      publish("changeConversation", existingConv);
      return existingConv
    }
  };

  const selectConversation = (conv) => {
    setSelectedConversation({ ...conv });
    if (window.location.pathname.indexOf("/mascot/" + mascotId + "/chat/") === 0) {
      history.push("/mascot/" + mascotId + "/chat");
    }
    publish("changeConversation", conv);
  };

  const setNewConversation = (conv) => {
    setConversations((conversations) => {
      const newConv = conversations.filter((c) => {
        return c._id !== "new";
      });
      setSelectedConversation(conv);
      return [conv, ...newConv];
    });
  };

  const updateConversationTitle = (title) => {
    selectedConversation.title = title;
    setSelectedConversation(selectedConversation);
    if (selectedConversation._id !== "new") {
      setConversations((conversations) => {
        client.updateConversationTitle(selectedConversation);
        let newConvs = conversations.map((c) => {
          if (c._id === selectedConversation._id) {
            c.title = title;
          }
          return c;
        });
        return newConvs;
      });
    }
  };

  const updateConversationLastPrompt = (conv) => {
    let newConvs = conversations.map((c) => {
      if (c._id === conv._id) {
        c.lastPrompt = conv.lastPrompt;
      }
      return c;
    });
    setConversations([...newConvs]);
  };

  const updateConversationToken = (conv) => {
    let newConvs = conversations.map((c) => {
      if (c._id === conv._id) {
        c.shareToken = conv.shareToken;
      }
      return c;
    });
    setConversations([...newConvs]);
    setSelectedConversation(conv);
  };

  const removeConversation = (convId) => {
    const newConv = conversations.filter((c) => {
      return c._id !== convId;
    });
    selectConversation(newConv.length > 0 ? newConv[0] : {});
    setConversations([...newConv]);
  };

  return {
    mascot,
    conversations,
    selectedConversation,
    createConversation,
    setSelectedConversation,
    selectConversation,
    loadMoreConversation,
    updateConversationTitle,
    updateConversationLastPrompt,
    setNewConversation,
    updateConversationToken,
    removeConversation,
  };
}

export function ProvideConversations({ children }) {
  const conversations = useProvideConversations();
  return <conversationsContext.Provider value={conversations}>{children}</conversationsContext.Provider>;
}

export const useConversations = () => {
  return useContext(conversationsContext);
};
