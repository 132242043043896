import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Context
import { useExtension } from "../hooks/useExtension";
import { useMascot } from "../hooks/useMascot";

export default function MascotExtensionBar() {
  const { sendMessageExtension } = useExtension();
  const [mascots, setMascots] = useState([]);
  const [cursorToggled, setCursorToggled] = useState(false);
  const { mascot } = useMascot();
  const history = useHistory();

  useEffect(() => {
    sendMessageExtension("getInstalledMascot").then((res) => {
      setMascots(res);
    });
    // eslint-disable-next-line
  }, []);

  const selectMascot = (m) => {
    history.push(`/mascot/${m}/chat`);
  };

  const removeMascot = async (m) => {
    let data = await sendMessageExtension("removeInstalledMascot", m);
    setMascots(data);
  };

  useEffect(() => {
    sendMessageExtension("getCursorStatus").then((res) => {
      if (res && res.cursorStatus !== undefined) {
        setCursorToggled(res.cursorStatus);
      }
    });
  }, [sendMessageExtension]);
  
  const toggleCursor = async (cursorStatus) => {
    let res = await sendMessageExtension("toggleCursor", { cursorStatus });
    if (res && res.cursorStatus !== undefined) {
      setCursorToggled(res.cursorStatus);
    }
  };

  return (
    <div className="mascot-extension-bar">
      <div className="select xsmall transparent">
        <select value={mascot._id} onChange={(e) => selectMascot(e.target.value)}>
          {mascots.map((m) => (
            <option key={m._id} value={m._id}>
              {m.name}
            </option>
          ))}
        </select>
      </div>
      
      <div className="switch-wrapper dark small">
        <label>Cursor</label>
        <div className="switch">
          <input
            type="checkbox"
            checked={cursorToggled}
            onChange={() => toggleCursor(!cursorToggled)}
          />
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="icon-btn light" onClick={() => removeMascot(mascot._id)}>
        <i className="icon-remove"></i>
      </div>
    </div>
  );
}
