import React, { useState, useCallback } from "react";
import { toast } from "react-toastify";
import ConfluenceFilePicker from "../components/ConfluenceFilePicker";
import { MascotDataHeader } from "../components/MascotDataHeader";
import { servicesClient } from "../services/servicesApi";
import { useAuth } from "../hooks/useAuth";
import { ListDataFile } from "../components/ListDataFile";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import { useTasks } from "../hooks/useTasks";
import { useEditMascot } from "../hooks/useEditMascot";
import IconConfluence from "../images/icons/confluence.svg";

export default function EditMascotDataConfluence() {
  const { currentOrganization, currentUser } = useAuth();
  const { embeddingTask, addTask, taskList, isUpdating } = useTasks();
  const { mascot, updateDataLastModified } = useEditMascot();
  const [isShownFilePicker, setShownFilePicker] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUpdateLoader, setShowUpdateLoader] = useState(false);

  const authorizeUser = async () => {
    const result = await servicesClient.authorizeConfluence(currentOrganization._id, window.location.href);
    if (result.ok) {
      window.location.href = result.data.authUrl;
    }
  };

  const browse = async () => {
    const result = await servicesClient.getConfluenceToken(currentOrganization._id);
    const token = result.ok && result.data.accessToken;

    if (!token) {
      if (mascot.data?.uploads.filter((doc) => doc.type === "confluence").length > 0) {
        setShowConfirmModal(true);
      } else {
        authorizeUser();
      }
    } else {
      setShownFilePicker(true);
    }
  };

  const onSelect = useCallback(
    async (files) => {
      const filteredFiles = files.filter((file) => {
        if (
          mascot.data?.uploads
            .filter((doc) => doc.type === "confluence")
            .some((u) => `${u.nodeType}-${u.confluenceId}` === file.id) ||
          taskList
            .filter((doc) => doc.type === "addConfluenceTask" && doc.status === "queued")
            .some((u) => u?.meta?.filename === file.name)
        ) {
          toast.error(() => (
            <>
              <b>{file.name}</b>: Duplicate files cannot be uploaded.
            </>
          ));
          return false;
        }
        return true;
      });
      const result = await servicesClient.dataAddConfluence(mascot._id, filteredFiles, currentUser._id);
      if (!result.ok || result.data.error) {
        console.log("Upload Error! " + result.originalError);
        toast.error(() => (
          <>
            <b>{filteredFiles[0].name}</b>: {String(result.originalError)}.
          </>
        ));
        return;
      }
      result.data?.forEach((item) => addTask(item));
    },
    [mascot, currentUser, addTask, taskList]
  );

  const checkUpdates = async () => {
    setShowUpdateLoader(true);
    const result = await servicesClient.dataCheckUpdatesConfluence(mascot._id);
    setShowUpdateLoader(false);
    if (result.ok) {
      for (const [key, value] of Object.entries(result.data)) {
        value === null &&
          toast.error(() => (
            <>
              <b>Cannot update file:</b> "{mascot.data?.uploads.find((u) => u._id === key)?.name}" was deleted from Confluence.
            </>
          ));
      }
      updateDataLastModified(result.data);
    }
  };

  const handleRefreshDoc = async (upload) => {
    let pendingUpdateTask = isUpdating(upload);
    if (!pendingUpdateTask) {
      const result = await servicesClient.dataUpdateConfluence(mascot._id, upload._id, currentUser._id);
      result.ok && addTask({ ...result.data });
    } else {
      addTask({
        status: "failed",
        type: "confluence",
        meta: {
          error: "Update already in progress",
          filename: pendingUpdateTask.meta.filename,
          mimeType: pendingUpdateTask.meta.mimeType,
        },
      });
    }
  };

  const getFileAction = (doc) => (
    <>
      {!embeddingTask && doc.lastModified > doc.lastUpdate && (
        <i
          className="icon-reload"
          onClick={() => {
            handleRefreshDoc(doc);
          }}
        ></i>
      )}
    </>
  );

  const getFileActionPermanent = (doc) => (
    <>
      {!embeddingTask &&
        doc.lastModified > doc.lastUpdate &&
        doc.confluenceAccount === currentOrganization.confluenceConnected && (
          <span className="meta small warning">This file has changes, please update</span>
        )}
      {!embeddingTask && doc.confluenceAccount !== currentOrganization.confluenceConnected && (
        <span className="meta small">Not synced (account changed)</span>
      )}
    </>
  );

  const docsToUpdate = (docs) => {
    return docs.filter(
      (doc) =>
        doc.type === "confluence" &&
        doc.lastModified > doc.lastUpdate &&
        doc.confluenceAccount === currentOrganization.confluenceConnected
    );
  };

  return (
    <div>
      <MascotDataHeader></MascotDataHeader>

      <div className="data-pane full-width">
        {!currentOrganization.confluenceConnected ? (
          <>
            <h3>
              <img src={IconConfluence} alt={IconConfluence}></img> Connect Atlassian Confluence
            </h3>

            <p>Connect your Atlassian account to sync content from Confuence to your mascot.</p>
          </>
        ) : (
          <>
            <h3>
              <img src={IconConfluence} alt={IconConfluence}></img> Confluence Files
            </h3>
          </>
        )}

        {mascot.data && mascot.data?.uploads && mascot.data?.uploads.filter((doc) => doc.type === "confluence").length > 0 ? (
          <ListDataFile
            title="Uploaded Files"
            searchLabel="Search files"
            getFileAction={getFileAction}
            getFileActionPermanent={getFileActionPermanent}
            disabled={!!embeddingTask}
            handleRefreshDoc={handleRefreshDoc}
            docsToUpdate={docsToUpdate}
            buttonBar={
              <>
                <button className="small" onClick={browse} disabled={embeddingTask}>
                  {currentOrganization.confluenceConnected ? "Browse Files" : "Connect Account"}
                </button>

                {currentOrganization.confluenceConnected && (
                  <button className="small" disabled={showUpdateLoader} onClick={checkUpdates}>
                    {showUpdateLoader && <Loader classNames="small"></Loader>}
                    <span>Check For Updates</span>
                  </button>
                )}
              </>
            }
            files={
              mascot.data && mascot.data?.uploads && mascot.data?.uploads.filter((doc) => doc.type === "confluence").reverse()
            }
          ></ListDataFile>
        ) : (
          <button className="small" onClick={browse} disabled={embeddingTask}>
            {currentOrganization.confluenceConnected ? "Browse Files" : "Connect Account"}
          </button>
        )}

        <ConfluenceFilePicker isShown={isShownFilePicker} setShown={setShownFilePicker} onSelect={onSelect} />
        <Modal
          title="Connect to Confluence"
          size="small"
          isOpen={showConfirmModal}
          close={() => setShowConfirmModal(false)}
          action={
            <>
              <button className="action" onClick={authorizeUser}>
                Continue
              </button>
              <button onClick={() => setShowConfirmModal(false)}>Cancel</button>
            </>
          }
        >
          <p>Existing Confluence documents will not sync if connecting with a different Confluence account.</p>
        </Modal>
      </div>
    </div>
  );
}
