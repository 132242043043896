import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import classNames from "classnames";
import Loader from "../Loader";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function CompareBarChart({ data: inputData, onClick, title, className, label }) {
  const [data, setData] = useState(inputData);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] > 0;
        },
        clamp: true,
        color: "#000",
        anchor: "end",
        align: "bottom",
        labels: {
          title: {
            font: {
              weight: "bold",
              size: 18,
              family: "Lexend",
            },
          },
        },
      },
    },
    scales: {
      y: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          borderColor: "#324D59",
          borderWidth: 1,
          drawBorder: true,
          display: false,
        },
        ticks: {
          display: true,
          color: "#f7f7f7",
          font: {
            family: "Lexend",
            size: 11,
          },
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
    animations: false,
    onClick: (e, clickedElements) => {
      if (onClick) onClick(inputData[clickedElements[0].index].date);
    },
  };

  useEffect(() => {
    if (inputData) {
      setData({
        labels: inputData.map((d) => d.label),
        datasets: [
          {
            data: inputData.map((d) => d.value),
            categoryPercentage: 1,
            barPercentage: 0.7,
            borderRadius: 20,
            maxBarThickness: 88,
            backgroundColor: function (context) {
              if (!context.chart.chartArea) return;
              if (context.dataIndex === 0) {
                return "#949494";
              }

              let gradient = context.chart.ctx.createLinearGradient(
                0,
                context.chart.chartArea.bottom,
                0,
                context.chart.chartArea.top
              );
              gradient.addColorStop(0, "#3DBFBF");
              gradient.addColorStop(1, "#2DE279");
              return gradient;
            },
          },
        ],
      });
    }
  }, [inputData]);

  return (
    <div className={classNames("chart", "compare-chart", className && className)}>
      <h4>{title}</h4>

      {data ? (
        data.datasets && data.datasets[0].data.reduce((acc, curr) => acc + curr, 0) > 0 ? (
          <>
            <Bar options={options} data={data} />
            <div className="meta chart-label">{label}</div>
          </>
        ) : (
          <h5 className="no-data">No data yet</h5>
        )
      ) : (
        <Loader classNames="dark large"></Loader>
      )}
    </div>
  );
}
