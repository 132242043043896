import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { publish } from "../services/events";
import { useAuth } from "./useAuth";
import { client } from "../services/api";
import { useMascot } from "./useMascot";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { clientId, currentUser, updateOrganization } = useAuth();
  const { mascotId } = useMascot();

  useEffect(() => {
    if (currentUser && socket) {
      socket.emit("setUserId", { userId: currentUser._id });
    }
  }, [currentUser, socket]);

  useEffect(() => {
    if (!clientId) {
      return;
    }
    const socket = io(process.env.REACT_APP_URL_SERVICES, {
      transports: ["websocket", "polling"],
      withCredentials: true,
      query: {
        clientId: clientId,
      },
    });
    setSocket(socket);
  }, [clientId]);

  async function onSlackDisconnected(data) {
    let result = await client.getOrganization(data.organization);
    if (result.ok) {
      updateOrganization(result.data);
    }
  }

  useEffect(() => {
    if (!socket) return;

    function onConnect() {
      console.log("ws connected");
    }

    function onDisconnect() {
      console.log("ws disconnected");
    }

    async function onAddFileTask(data) {
      if (!data.result || data.result.nonTrainingFileType === "chat_attachment") {
        publish("onAttachmentParsed", data);
      }

      onTaskCompleted(data);
    }

    async function onTaskCompleted(data) {
      let mascotIdTask = data.result ? data.result.mascotId || data.result._id.$oid : data.meta.mascotId;
      if (mascotId === mascotIdTask) {
        let mascotData = await client.getMascot(mascotIdTask);
        publish("onTaskCompleted", mascotData.data);
      }
    }

    function onIntegrateFeedbackTaskEvent(data) {
      publish("FeedbackSubmitted", data);
    }

    function onGenerateEmbeddingTaskEvent(data) {
      let mascotIdTask = data.result ? data.result._id.$oid : data.meta.mascotId;
      if (mascotId === mascotIdTask) {
        publish("generateEmbeddingTask", data);
        onTaskCompleted(data);
      }
    }

    function onMessageSent(data) {
      publish("onMessageSent", data);
    }

    const keepAliveIntervalId = setInterval(() => {
      socket.emit("keep-alive", { timestamp: Date.now() });
    }, 25000);

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("addFileTask", onAddFileTask);
    socket.on("addWebTask", onTaskCompleted);
    socket.on("addGoogleDriveTask", onTaskCompleted);
    socket.on("addConfluenceTask", onTaskCompleted);
    socket.on("addOneDriveTask", onTaskCompleted);
    socket.on("addSharepointTask", onTaskCompleted);
    socket.on("addSharepointPageTask", onTaskCompleted);
    socket.on("addZendeskTask", onTaskCompleted);
    socket.on("refreshWebTask", onTaskCompleted);
    socket.on("refreshGoogleDriveTask", onTaskCompleted);
    socket.on("refreshConfluenceTask", onTaskCompleted);
    socket.on("refreshOneDriveTask", onTaskCompleted);
    socket.on("refreshSharepointTask", onTaskCompleted);
    socket.on("refreshZendeskTask", onTaskCompleted);
    socket.on("integrateFeedbackTask", onIntegrateFeedbackTaskEvent);
    socket.on("generateEmbeddingTask", onGenerateEmbeddingTaskEvent);
    socket.on("slackDisconnected", onSlackDisconnected);
    socket.on("messageSent", onMessageSent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("addFileTask", onTaskCompleted);
      socket.off("addWebTask", onTaskCompleted);
      socket.off("addGoogleDriveTask", onTaskCompleted);
      socket.off("addConfluenceTask", onTaskCompleted);
      socket.off("addOneDriveTask", onTaskCompleted);
      socket.off("addSharepointTask", onTaskCompleted);
      socket.off("addSharepointPageTask", onTaskCompleted);
      socket.off("addZendeskTask", onTaskCompleted);
      socket.off("refreshWebTask", onTaskCompleted);
      socket.off("refreshGoogleDriveTask", onTaskCompleted);
      socket.off("refreshConfluenceTask", onTaskCompleted);
      socket.off("refreshOneDriveTask", onTaskCompleted);
      socket.off("refreshSharepointTask", onTaskCompleted);
      socket.off("refreshZendeskTask", onTaskCompleted);
      socket.off("integrateFeedbackTask", onIntegrateFeedbackTaskEvent);
      socket.off("generateEmbeddingTask", onGenerateEmbeddingTaskEvent);
      socket.off("slackDisconnected", onSlackDisconnected);
      socket.off("messageSent", onMessageSent);
      clearInterval(keepAliveIntervalId);
    };
    // eslint-disable-next-line
  }, [socket, mascotId]);

  return <SocketContext.Provider value={{ socket: socket }}>{children}</SocketContext.Provider>;
};

export const useSocket = () => {
  return useContext(SocketContext);
};
