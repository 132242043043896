import React, { useState } from "react";
import classNames from "classnames";
import MascotImage from "../components/MascotImage";
import ColorPicker from "../ui/ColorPicker";
import { useEditMascot } from "../hooks/useEditMascot";
import moment from "moment";

// Theme fonts
export const fontList = [
  "Aleo",
  "Antic Slab",
  "Arvo",
  "Barlow",
  "Bitter",
  "Cairo",
  "Crete Round",
  "Crimson Text",
  "Domine",
  "Josefin Sans",
  "Lato",
  "Lora",
  "Lexend",
  "Martel",
  "Merriweather",
  "Montserrat",
  "Noto Serif",
  "Nunito Sans",
  "Open Sans",
  "Oswald",
  "Poppins",
  "PT Sans",
  "PT Serif",
  "Quicksand",
  "Raleway",
  "Roboto",
  "Roboto Slab",
  "Rubik",
  "Source Sans Pro",
  "Source Serif Pro",
  "Work Sans",
  "Zilla Slab",
];

export default function EditMascotTheme() {
  const { mascotDraft, saveDraft } = useEditMascot();
  const [fontDropdownVisible, setFontDropdownVisible] = useState(false);

  // Update Mascot
  const setValue = (key, value) => {
    const mascot = mascotDraft;
    if (!value) return;
    if (!mascot.theme) {
      mascot.theme = {};
    }
    if (mascot.theme[key] === value) return;

    mascot.theme[key] = value;
    saveDraft(mascot);
  };

  // Font select
  const fonts = fontList.map((font, index) => {
    return (
      <div className="option" onClick={() => setValue("fontFamily", font)} key={index} style={{ fontFamily: String(font) }}>
        {font}
      </div>
    );
  });

  const loadFonts = fontList.map((font, i) => {
    let truncatedFontName = font.replace(/\s/g, "+");

    return <link rel="stylesheet" key={i} href={`https://fonts.googleapis.com/css2?family=${truncatedFontName}&display=swap`} />;
  });

  const handleCloseFontDropdown = () => {
    setFontDropdownVisible(false);
    document.removeEventListener("click", handleCloseFontDropdown);
  };

  const handleFontDropdown = (e) => {
    e.stopPropagation();
    if (fontDropdownVisible) return handleCloseFontDropdown();

    setFontDropdownVisible(true);
    document.addEventListener("click", handleCloseFontDropdown);
  };

  const handleDarkTheme = (e) => {
    saveDraft({
      ...mascotDraft,
      theme: {
        ...mascotDraft.theme,
        darkTheme: e.target.checked,
      },
    });
  };

  return (
    <div>
      <div className="edit-mascot-header">
        <h1>Theme</h1>
      </div>

      <div className="content-wrapper full-width col-2">
        <div className="content-col">
          <h3>Color Settings</h3>

          <ColorPicker
            label="Brand Color"
            defaultValue={(mascotDraft.theme && mascotDraft.theme.brandColor) || ""}
            handleThemeColor={(e) => setValue("brandColor", e)}
          />

          <ColorPicker
            label="Text Color"
            defaultValue={(mascotDraft.theme && mascotDraft.theme.accentColor) || ""}
            handleThemeColor={(e) => setValue("accentColor", e)}
          />

          <div className="input-group">
            <label>Font Family</label>

            <link rel="preconnect" href="https://fonts.gstatic.com" />

            {loadFonts}

            <div className="dropdown">
              <input
                readOnly
                type="text"
                onClick={handleFontDropdown}
                value={mascotDraft.theme && mascotDraft.theme.fontFamily}
                style={{ fontFamily: mascotDraft.theme && mascotDraft.theme.fontFamily }}
              />
              <div className={classNames("dropdown-options", fontDropdownVisible && "active")}>{fonts}</div>
            </div>
          </div>

          <div className="switch-wrapper">
            <label>Enable Dark Theme</label>
            <span className="meta small">
              This will invert the theme of the mascot to a dark tone. You may need to adjust your colors above to match.
            </span>
            <div className="switch">
              <input
                type="checkbox"
                defaultChecked={mascotDraft.theme && mascotDraft.theme.darkTheme}
                onChange={handleDarkTheme}
              />
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <div className="content-col">
          <div 
            className={classNames(
              'preview-window square mascot-chat',
              mascotDraft.theme && mascotDraft.theme.darkTheme ? "dark-theme" : "light-theme"
            )}
          >
            <div className="preview-content mascot-chat-panel">
              <span className="meta">Preview</span>

              {/* CHAT PREVIEW */}
              <div className="chat-body" style={{ fontFamily: mascotDraft.theme && mascotDraft.theme.fontFamily }}>
                <div className="messages-wrapper">
                  <div className="messages">
                    <div className="message user" style={{ borderColor: mascotDraft.theme && mascotDraft.theme.brandColor }}>
                      <div className="message-header">
                        <div
                          className="user-avatar"
                          style={{ backgroundColor: mascotDraft.theme && mascotDraft.theme.brandColor }}
                        >
                          <h3>U</h3>
                        </div>
                        <div className="message-header-title">
                          <h5>User</h5>
                          <span className="meta small">{new moment().format("MMM Do, hh:mma")}</span>
                        </div>
                      </div>
                      <div className="message-content">
                        <div>
                          <p style={{ color: mascotDraft.theme && mascotDraft.theme.accentColor }}>
                            This is message sent from the user
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="message assistant">
                      <div className="message-header">
                        <MascotImage mascot={mascotDraft}></MascotImage>

                        <div className="message-header-title">
                          <h5>{mascotDraft.name}</h5>
                          <span className="meta small">{new moment().format("MMM Do, hh:mma")}</span>
                        </div>
                      </div>

                      <div className="message-content">
                        <div>
                          <p style={{ color: mascotDraft.theme && mascotDraft.theme.accentColor }}>
                            This is a reply from the mascot
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="new-message">
                  <div className="icon-btn btn-attachment">
                    <i className="icon-paperclip"></i>
                  </div>

                  <textarea className="new-message-field large" placeholder="Ask me anything" />

                  <button className="positive btn-send-message" style={{ backgroundColor: mascotDraft.theme && mascotDraft.theme.brandColor }}>
                    <i className="icon-chevron-right"></i>
                  </button>
                </div>
              </div>
              {/* END CHAT PREVIEW */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
