import React, { useState, useEffect, useCallback, useRef } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

// Libs
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { useParams } from "react-router-dom";
import { client } from "../services/api";
import { servicesClient } from "../services/servicesApi";
import MDEditor from "@uiw/react-md-editor";
import { subscribe, unsubscribe } from "../services/events";

// Context
import { useSocket } from "../hooks/ws.js";
import { useAuth } from "../hooks/useAuth";
import { useTasks } from "../hooks/useTasks";

// Components
import { useHistory } from "react-router-dom";
import MascotSidebarChat from "../components/MascotSidebarChat";
import MascotImage from "../components/MascotImage";
import MascotExcerpts from "../components/MascotExcerpts";
import TrainingModeSidebar from "../components/TrainingModeSidebar";
import SelectTextPopup from "../components/SelectTextPopup";
import Modal from "../components/Modal";
import Popover from "../components/Popover";
import Loader from "../components/Loader";

// Images
import HourGlass from "../images/hourglass.svg";
import Logo from "../images/logo-wiseox.svg";
import LogoDark from "../images/logo-wiseox-dark.svg";

import MessageChat from "../components/MessageChat.js";
import { useConversations } from "../hooks/useConversations.js";
import { useMessages } from "../hooks/useMessages.js";
import StatusSuccess from "../images/icons/status-success.svg";
import StatusWarning from "../images/icons/status-warning.svg";
import MascotChatAuth from "./MascotChatAuth.js";
import IconPDF from "../images/icons/pdf.svg";
import IconImage from "../images/icons/image.svg";
import { FileIcon } from "../components/FileIcon.js";
import MascotExtensionBar from "../components/MascotExtensionBar.js";

export default function MascotChat() {
  // Info
  const { mascotId, conversationId } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const selectedMessage = searchParams.get("selectedMessage");

  // Context
  const {
    mascot,
    selectedConversation,
    updateConversationTitle,
    conversations,
    updateConversationToken,
    removeConversation,
    selectConversation,
    createConversation,
  } = useConversations();
  const {
    getPreviousMessages,
    handleStopStream,
    handleSubmitWs,
    setMessageContext,
    streamingMessage,
    runningAnswer,
    setInitSessionStats,
    loadingConversation,
    conversation,
    setConversation,
    loading,
    chatEndEl,
    chatWrapperEl,
    messages,
    setMessages,
    newMessage,
    setNewMessage,
    attachments,
    setAttachments,
    functionExecution,
  } = useMessages();
  const { embeddingTask, embeddingTaskLastResult } = useTasks();
  const { isAuthenticated, isGuestAuthenticated, clientId, currentOrganization, currentUser, isExtension } = useAuth();
  const { socket } = useSocket();

  // State
  const [editingTitle, setEditingTitle] = useState();
  const [showExcerpts, setShowExcerpts] = useState(false);
  const [newMessageFocus, setNewMessageFocus] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [trainingMode, setTrainingMode] = useState(false);
  const [showedPopup, setShowedPopup] = useState([]);
  const [showOptionsPopover, setShowOptionsPopover] = useState(false);
  const [showErrorPopover, setShowErrorPopover] = useState(false);
  const [showSuccessPopover, setShowSuccessPopover] = useState(false);
  const [pendingMessage, setPendingMessage] = useState(null);

  // Modals
  const [showConversationStatsModal, setShowConversationStatsModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  // Modals vars
  const [selectedDataFormat, setSelectedDataFormat] = useState("json");
  const [aggregatedStatsSessions, setAggregatedStatsSessions] = useState({});
  const [refreshingConversationStats, setRefreshingConversationStats] = useState(false);
  const [isShowModalFeedback, setShowModalFeedback] = useState();
  const [addedFeedback, setAddedFeedback] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState("");
  const [feedbackValueInit, setFeedbackValueInit] = useState("");
  const [errorFeedback, setErrorFeedback] = useState();

  // Consts
  const classPopover = ".message.assistant .message-content";
  const textareaEl = useRef();
  const history = useHistory();
  const refAttachment = useRef();

  // Init
  const fetchStatsSessionsByConversation = async (conversationId) => {
    return await client
      .getStatsSessionsByConversation(conversationId)
      .then((res) => res.ok && setAggregatedStatsSessions(res.data));
  };

  useEffect(() => {
    if (conversationId && conversations.length > 0 && selectedConversation._id !== conversationId) {
      let conv = conversations.find((c) => c._id === conversationId);
      selectConversation({ ...conv });
    }
    // eslint-disable-next-line
  }, [conversationId, conversations]);

  useEffect(() => {
    subscribe("onAttachmentParsed", onAttachmentParsed);
    return () => {
      unsubscribe("onAttachmentParsed", onAttachmentParsed);
    };
    // eslint-disable-next-line
  }, [attachments]);

  // Attachments

  const currentConversationAttachments = attachments.filter(
    (attachment) => attachment.attachmentConversationId === conversation._id
  );

  const onAttachmentParsed = (taskResult) => {
    for (let a in attachments) {
      let attachment = attachments[a];
      if (attachment.taskId === taskResult.detail.taskId) {
        if (taskResult.detail.result) {
          attachment.parsing = false;
          attachment._id = taskResult.detail.result._id;
        } else {
          attachments.splice(a, 1);
          toast.error(taskResult.detail.value || "Error uploading attachment");
        }
      }
    }

    setAttachments([...attachments]);
  };

  // Change conversation
  useEffect(() => {
    subscribe("changeConversation", changeConversation);

    return () => {
      unsubscribe("changeConversation", changeConversation);
    };
    // eslint-disable-next-line
  }, [mascot]);

  const changeConversation = (data) => {
    let currentConversation = data.detail;
    setConversation({ ...currentConversation });
    setEditingTitle(false);
    getPreviousMessages(currentConversation._id);
    if (mascot.ownMascot && currentConversation._id !== "new") {
      fetchStatsSessionsByConversation(currentConversation._id);
    }
  };

  // Embedding
  useEffect(() => {
    // Send ready message to parent Iframe
    if (window.parent && mascot) {
      window.parent.postMessage(
        {
          type: "wiseox-mascot-ready",
          mascot: mascot,
        },
        "*"
      );
    }

    // eslint-disable-next-line
  }, [mascot]);

  useEffect(() => {
    window.addEventListener("message", handlePostMessage);
    return () => window.removeEventListener("message", handlePostMessage);
    // eslint-disable-next-line
  }, [socket, conversation, messages, loading]);

  // Use this trick to call an updated version of handleSubmitWs
  useEffect(() => {
    if (pendingMessage) {
      handleSubmitWs(pendingMessage.message, false);
      setPendingMessage(null);
    }
  }, [pendingMessage, handleSubmitWs]);

  const handlePostMessage = useCallback(
    async (e) => {
      if (e.data.type === "send-message" && !loading) {
        if (e.data.startNewConversation) {
          const conv = createConversation();
          changeConversation({ detail: conv });
        }
        setMessageContext(e.data.pageContext);
        setPendingMessage({ message: e.data.message });

      } else if (e?.data?.type === "prepare-message" && !loading) {
        if (e.data.startNewConversation) {
          const conv = createConversation();
          changeConversation({ detail: conv });
        }
        setMessageContext(e.data.pageContext);
        setNewMessage("[Enter a prompt here] \n" + e.data.message)
      } else if (e?.data?.type === "send-context") {
        setMessageContext(e.data.pageContext);
      } else if (e?.data?.type === "add-image") {
        let file = base64ToFile(e.data.file, "image.jpg");

        handleAttachmentUpload({
          currentTarget: {
            files: [file],
          },
          preventDefault: () => {},
        });
      }
    },
    // eslint-disable-next-line
    [socket, conversation, messages, loading]
  );

  function base64ToFile(base64, fileName) {
    // Split the Base64 string into two parts: the header and the data
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1]; // Extract the MIME type
    const bstr = atob(arr[1]); // Decode Base64 to binary string
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n); // Convert binary string to bytes
    }

    // Create a File object with the byte array, file name, and MIME type
    return new File([u8arr], fileName, { type: mime });
  }

  const refreshConversationStats = async () => {
    if (!selectedConversation) return;
    setRefreshingConversationStats(true);
    let runReq = await servicesClient.runSessionstatsAnalysisByConversation(selectedConversation._id);
    if (runReq.ok) {
      await fetchStatsSessionsByConversation(selectedConversation._id);
    }
    setRefreshingConversationStats(false);
  };

  const saveTitle = () => {
    if (!conversation.title) {
      toast.error("Conversation title is required");
      return;
    }
    setEditingTitle(false);
    updateConversationTitle(conversation.title);
  };

  const onKeyDown = function (e) {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      if (!loading) {
        handleSubmitWs();
      }
      e.preventDefault();
    }
  };

  const doneTraining = function () {
    setTrainingMode(false);
    setShowOptionsPopover(false);

    if (addedFeedback) {
      servicesClient.doneTraining(mascotId, conversation._id, { clientId });
      const promiseToastTraining = new Promise((resolve, reject) => {
        subscribe("FeedbackSubmitted", (data) => {
          if ("error" in data) {
            reject(data);
          } else {
            resolve();
          }
        });
      });

      toast.promise(promiseToastTraining, {
        pending: "Submitting Feedback",
        success: "Feedback Submitted!",
        error: {
          render({ data }) {
            return (
              <>
                Error Submitting Feedback!
                <br />
                <small>{data.value}</small>
              </>
            );
          },
        },
      });
    }
    setAddedFeedback(false);
  };

  const togglePopup = (i) => {
    showedPopup[i] = !showedPopup[i];
    setShowedPopup([...showedPopup]);
  };

  const addRank = (i, msg, feedback, correctAnswer) => {
    setAddedFeedback(true);
    togglePopup(i);
    msg.feedback = feedback;
    msg.correctAnswer = correctAnswer;
    messages[i.i] = msg;
    setMessages([...messages]);

    let conversationId = selectedConversation._id;
    setInitSessionStats((initSessionStats) => {
      if (!initSessionStats[conversationId] && conversationId !== "new") {
        client.createSessionStats(conversationId, clientId).then((res) => {
          if (res.ok) {
            initSessionStats[conversationId] = res.data?._id;
            client.addFeedbackMessage(conversationId, msg._id, feedback, correctAnswer, res.data._id);
            setInitSessionStats(initSessionStats);
          }
        });
        return initSessionStats;
      } else {
        client.addFeedbackMessage(conversationId, msg._id, feedback, correctAnswer, initSessionStats[conversationId]);
        return initSessionStats;
      }
    });
  };

  const showModalFeedback = (i) => {
    setFeedbackValueInit(messages[i].correctAnswer || messages[i].text);
    setFeedbackValue(messages[i].correctAnswer || messages[i].text);
    let foundMessage = messages.find((m) => m._id === messages[i]?.prompt);
    if (!foundMessage) {
      console.warn("No matching message found for prompt:", messages[i]?.prompt);
    } else {
      setShowModalFeedback({ ...messages[i], promptText: foundMessage.text, ...{ i } });
    }
  };

  const submitFeedback = () => {
    if (feedbackValue === feedbackValueInit) {
      setErrorFeedback("You must provide an edited response in order to submit the feedback");
    } else {
      addRank(isShowModalFeedback, isShowModalFeedback, "negative", feedbackValue);
      setShowModalFeedback(false);
    }
  };

  const exportData = async () => {
    let result = await client.exportConversationData(selectedConversation._id, selectedDataFormat);
    if (result.ok) {
      downloadFile(result.data.fileName, result.data.content);
    }
  };

  const downloadFile = (fileName, content) => {
    const url = window.URL.createObjectURL(new Blob([content]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const openShareModal = async () => {
    if (!selectedConversation.shareToken) {
      let res = await client.generateShareConversationToken(selectedConversation._id);
      updateConversationToken(res.data);
    }
    setShowShareModal(true);
  };

  const archive = async () => {
    await client.archiveConversation(selectedConversation._id);
    removeConversation(selectedConversation._id);
    setShowOptionsPopover(false);
    setShowArchiveModal(false);
  };

  const handleAttachmentUpload = async (event) => {
    event.preventDefault();

    if (currentConversationAttachments.filter((a) => a.messageId === undefined).length >= 5) {
      return toast.error("You cannot upload more than 5 files at once");
    }

    if (currentConversationAttachments.find((a) => a.parsing)) {
      return toast.error("Wait for the current doc to finish parsing");
    }

    // checking if attachment tokens < max tokens allows. This is lagging by 1 attachment
    // This gets Reset on re-render
    let totalAttachmentTokens = currentConversationAttachments.reduce(
      (sum, attachment) => sum + (attachment.tokensCount || 0),
      0
    );
    let maxAllowedAttachmentTokens = 32000;
    if (totalAttachmentTokens >= maxAllowedAttachmentTokens) {
      return toast.error("Attachments exceed allowed size");
    }

    let file = event.dataTransfer ? event.dataTransfer.files[0] : event.currentTarget.files[0];

    if (file) {
      if (file.size > 5000000) {
        return toast.error("Document size limited to 5MB");
      }

      refAttachment.current.value = "";
      try {
        const res = await servicesClient.dataAddAttachment(
          mascot._id,
          file,
          currentUser._id,
          selectedConversation._id,
          file.type
        );
        if (res.ok) {
          attachments.push({
            name: file.name,
            attachmentType: file.type,
            attachmentPreview: file.type.startsWith("image/") ? URL.createObjectURL(file) : IconPDF,
            attachmentConversationId: selectedConversation._id,
            parsing: true,
            nonTrainingFileType: "chat_attachment",
            taskId: res.data.id,
            uploader_id: res.data.args[2],
          });
          setAttachments([...attachments]);
        } else {
          toast.error("Error uploading the attachment");
        }
      } catch (e) {
        toast.error("Exception: Error uploading the attachment");
      }
    }
  };

  const handleSubmitAttachment = () => {
    refAttachment.current.click();
  };

  const renderAttachment = (attachment, i) => {
    if (attachment.attachmentType?.startsWith("image/")) {
      return (
        <div className="attachment-item attachment-img" key={"attachment_" + i} title={attachment.name}>
          <div className="remove-attachment" onClick={() => removeAttachment(attachment, i)}>
            <i className="icon-close"></i>
          </div>

          {attachment.parsing ? <Loader></Loader> : <img src={IconImage} alt="icon"></img>}

          <span className="meta">{attachment.name}</span>
        </div>
      );
    } else {
      return (
        <div className="attachment-item attachment-file" key={"attachment_" + i} title={attachment.name}>
          <div className="remove-attachment" onClick={() => removeAttachment(attachment, i)}>
            <i className="icon-close"></i>
          </div>

          {attachment.parsing ? <Loader></Loader> : <FileIcon type="file" doc={attachment} />}

          <span className="meta">{attachment.name}</span>
        </div>
      );
    }
  };

  const removeAttachment = (attachment) => {
    const updatedAttachments = attachments.filter((a) => a.uploadId !== attachment.uploadId);
    setAttachments([...updatedAttachments]);
    servicesClient.dataRemoveAttachment(mascot._id, attachment.uploadId);
  };

  if (mascot.public && mascot.guestAuthentication && !isAuthenticated && !isGuestAuthenticated) {
    return <MascotChatAuth />;
  }

  return (
    <>
      {isExtension && <MascotExtensionBar></MascotExtensionBar>}
      
      <div
        className={classNames(
          "mascot-wrapper mascot-chat",
          mascot?.theme?.darkTheme ? "dark-theme" : "light-theme",
          sidebarOpen && "sidebar-toggled",
          showExcerpts && "drawer-active",
          trainingMode && "drawer-active",
          isExtension && "chrome-extension"
        )}
        style={{ fontFamily: mascot.theme && mascot.theme.fontFamily }}
      >
        {!mascot.archived && 
          <MascotSidebarChat 
            loading={loading} 
            published={mascot.published} 
            setSidebarOpen={setSidebarOpen}
          />
        }

        <div className="mascot-chat-panel">
          {/* Load Libs */}
          <link rel="preconnect" href="https://fonts.gstatic.com" />

          {mascot.theme && mascot.theme.fontFamily && (
            <link
              rel="stylesheet"
              href={`https://fonts.googleapis.com/css2?family=${mascot.theme.fontFamily.replace(
                /\s/g,
                "+"
              )}:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap`}
            />
          )}

          {mascot.archived ? (
            <div className="not-ready">
              {/* Unavailable state */}
              <h2>
                {mascot.message === "Domain not allowed" ? "This mascot does not work on this site" : "This mascot is unavailable"}
              </h2>
            </div>
          ) : mascot._id && !mascot.published ? (
            <div className="not-ready">
              {/* Not ready state */}
              <img src={HourGlass} alt="not ready" />
              <h2>This mascot is not ready yet</h2>
            </div>
          ) : (
            <>
              {/* Header chat */}

              <div className="chat-header">
                <div className="chat-header-left">
                  {/* Open Sidebar for Mobile */}
                  <div className="icon-btn open-sidebar" onClick={() => setSidebarOpen(!sidebarOpen)}>
                    <i className="icon-sidebar"></i>
                  </div>

                  {embeddingTask ? (
                    <div className="mascot-status">
                      <Loader classNames={mascot?.theme?.darkTheme && "dark"}></Loader>
                      <span className="meta small">Updating training index</span>

                      <div style={{ position: "relative" }}>
                        <div className="icon-btn small" onClick={() => setShowSuccessPopover(true)}>
                          <i className="icon-info"></i>
                        </div>

                        {showSuccessPopover && (
                          <Popover className="status-popover has-caret" close={() => setShowSuccessPopover(false)}>
                            <p className="small">You will receive an email when the mascot is updated.</p>

                            <button className="xsmall" onClick={() => history.push(`/mascot/${mascotId}/edit/data`)}>
                              View Progress
                            </button>
                          </Popover>
                        )}
                      </div>
                    </div>
                  ) : (
                    embeddingTaskLastResult &&
                    (!embeddingTaskLastResult.error ? (
                      <div className="mascot-status">
                        <img src={StatusSuccess} alt={StatusSuccess} />
                        <span className="meta small">Mascot Ready</span>
                      </div>
                    ) : (
                      <div className="mascot-status">
                        <img src={StatusWarning} alt={StatusWarning} />
                        <span className="meta small">Issue Updating Mascot</span>

                        <div style={{ position: "relative" }}>
                          <div className="icon-btn small" onClick={() => setShowErrorPopover(true)}>
                            <i className="icon-info"></i>
                          </div>

                          {showErrorPopover && (
                            <Popover className="status-popover has-caret" close={() => setShowErrorPopover(false)}>
                              <p className="small">The mascot could not update with the latest training index.</p>

                              <button className="xsmall" onClick={() => history.push(`/mascot/${mascotId}/edit/data`)}>
                                Learn More
                              </button>
                            </Popover>
                          )}
                        </div>
                      </div>
                    ))
                  )}
                </div>

                <div className="chat-header-middle">
                  <div className="mobile-mascot-header">
                    <MascotImage mascot={mascot}></MascotImage>
                  </div>

                  <div className="chat-title">
                    {editingTitle ? (
                      <div className="edit-title">
                        <input
                          value={conversation.title}
                          autoFocus
                          onChange={(e) => setConversation({ ...conversation, title: e.target.value })}
                        />
                        <button className="xsmall" onClick={() => saveTitle()}>
                          Save
                        </button>
                      </div>
                    ) : (
                      <>
                        {!loadingConversation && (
                          <div>
                            <h6>
                              {conversation.title || new moment(conversation.created).format("MM-DD-YYYY hh:mm")}{" "}
                              <i className="icon-edit" onClick={() => setEditingTitle(true)}></i>
                            </h6>
                            <span className="meta small">
                              Started on {new moment(conversation.created).format("MMM Do, hh:mma")}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {!showExcerpts && isAuthenticated && mascot.ownMascot && mascot._id && (
                  <div className="chat-header-right">
                    {!trainingMode ? (
                      <div className="icon-btn" onClick={() => setShowOptionsPopover(true)}>
                        <i className="icon-ellipses"></i>

                        {showOptionsPopover && (
                          <Popover close={() => setShowOptionsPopover(false)} className="right options-popover">
                            <div className="popover-item" onClick={() => setShowConversationStatsModal(true)}>
                              <i className="icon-info"></i>
                              Conversation Stats
                            </div>

                            <div className="popover-item tune-mascot" onClick={() => setTrainingMode(true)}>
                              <i className="icon-filter"></i>
                              Tune Mascot
                            </div>

                            <div className="popover-item export-conversation" onClick={() => setShowExportModal(true)}>
                              <i className="icon-external-link"></i>
                              Export Conversation
                            </div>

                            <div className="popover-item" onClick={() => openShareModal()}>
                              <i className="icon-share"></i>
                              Share Conversation
                            </div>

                            <div className="popover-item danger" onClick={() => setShowArchiveModal(true)}>
                              <i className="icon-warn"></i>
                              Archive
                            </div>
                          </Popover>
                        )}
                      </div>
                    ) : (
                      <button className="xsmall" onClick={doneTraining}>
                        Done
                      </button>
                    )}
                  </div>
                )}
              </div>

              {/* Chat container */}
              <div className="chat-body">
                <div
                  className={classNames(
                    'messages-wrapper',
                    messages.length === 0 && 'no-messages'
                  )}
                  ref={chatWrapperEl}
                >
                  {loadingConversation ? (
                    <p className="empty-message">
                      <Loader></Loader>
                    </p>
                  ) : (
                    <>
                      {messages.length === 0 ? (
                        <div className="chat-empty">
                          {mascot?.prompt_settings?.reverse_prompt ? (
                            <h1 style={{ color: mascot.theme.brandColor }}>{mascot.prompt_settings.reverse_prompt_text}</h1>
                          ) : (
                            <h1 style={{ color: mascot.theme.brandColor }}>Hello, get started by asking {mascot.name} anything.</h1>
                          )}

                          {mascot?.topics?.length > 0 && (
                            <>
                              <p>Here are some topics to get you started</p>

                              <div className="chat-topics">
                                {mascot.topics.map((topic, i) => {
                                  return (
                                    <button
                                      disabled={loading}
                                      className="small"
                                      key={i}
                                      onClick={() => handleSubmitWs(topic.prompt, true)}
                                    >
                                      {topic.name}
                                    </button>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="messages">
                          {messages.map((message, i) => {
                            return (
                              <MessageChat
                                key={i}
                                message={message}
                                mascot={mascot}
                                trainingMode={trainingMode}
                                togglePopup={togglePopup}
                                i={i}
                                showedPopup={showedPopup}
                                addRank={addRank}
                                showModalFeedback={showModalFeedback}
                                setShowExcerpts={setShowExcerpts}
                                selectedMessage={selectedMessage}
                                referencesSelected={showExcerpts._id}
                                chatWrapperEl={chatWrapperEl}
                              ></MessageChat>
                            );
                          })}

                          {/* Streaming message */}
                          {((streamingMessage && selectedConversation && streamingMessage[selectedConversation._id]) ||
                            (functionExecution && selectedConversation && functionExecution[selectedConversation._id])) && (
                            <div className={classNames("message", "assistant", "streaming-message")}>
                              <div className="message-header">
                                <MascotImage mascot={mascot}></MascotImage>
                                <div className="message-header-title">
                                  <h5>{mascot.name}</h5>
                                </div>
                              </div>

                              <div className="message-content not-hoverable">
                                {functionExecution && selectedConversation && functionExecution[selectedConversation._id] && (
                                  <div className="message-function-execution meta">
                                    Searching <Loader classNames="small"></Loader>
                                  </div>
                                )}
                                <MDEditor.Markdown
                                  linkTarget="_blank"
                                  source={streamingMessage[selectedConversation._id]}
                                  data-color-mode="dark"
                                  style={{
                                    color: mascot.theme && mascot.theme.accentColor,
                                    fontFamily: mascot.theme && mascot.theme.fontFamily,
                                  }}
                                />
                              </div>
                            </div>
                          )}

                          <div ref={chatEndEl}></div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                {/* Send Message */}
                <div
                  className="new-message"
                  style={{ borderColor: newMessageFocus && mascot.theme && mascot.theme.brandColor }}
                  onDrop={handleAttachmentUpload}
                  title={currentConversationAttachments.find((a) => a.parsing) && "Wait for the current doc to finish parsing"}
                >
                  <div
                    className="icon-btn btn-attachment"
                    onClick={() => handleSubmitAttachment()}
                    disabled={currentConversationAttachments.find((a) => a.parsing)}
                  >
                    <i className="icon-paperclip"></i>
                  </div>

                  <input type="file" ref={refAttachment} style={{ display: "none" }} onChange={handleAttachmentUpload} />

                  <TextareaAutosize
                    ref={textareaEl}
                    maxRows={5}
                    className="new-message-field"
                    placeholder="Ask me anything"
                    onKeyDown={onKeyDown}
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                    onFocus={() => setNewMessageFocus(!newMessageFocus)}
                    onBlur={() => setNewMessageFocus(!newMessageFocus)}
                  />

                  <button
                    className="positive btn-send-message"
                    onClick={() => handleSubmitWs()}
                    disabled={loading || newMessage.length === 0 || currentConversationAttachments.find((a) => a.parsing)}
                    style={{ backgroundColor: mascot.theme && mascot.theme.brandColor }}
                  >
                    <i className="icon-chevron-right"></i>
                  </button>

                  {currentConversationAttachments.length > 0 && (
                    <div className="attachment-wrapper">
                      {/* TODO: This will break if attachments are being uploaded to multiple conversations at the same time */}
                      {currentConversationAttachments.map((a, i) => renderAttachment(a, i))}
                    </div>
                  )}

                  {/* Topics */}
                  {mascot?.topics?.length > 0 && messages.length > 0 && (
                    <div className="chat-topics">
                      {mascot.topics.map((topic, i) => {
                        return (
                          <button disabled={loading} className="small" key={i} onClick={() => handleSubmitWs(topic.prompt, true)}>
                            {topic.name}
                          </button>
                        );
                      })}
                    </div>
                  )}

                  {loading && (
                    <span className="responding">
                      <div className="responding-dots">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>

                      <span className="meta small">Responding</span>

                      {runningAnswer && (
                        <button className="xxsmall cancel" onClick={() => handleStopStream()}>
                          Cancel
                        </button>
                      )}
                    </span>
                  )}
                </div>

                {!currentOrganization?.features?.hideLogo && (
                  <div className="wiseox-badge">
                    <a href="https://wiseox.com" className="logo" target="_blank" rel="noreferrer">
                      <span>Powered By</span>
                      {mascot?.theme?.darkTheme ? <img src={Logo} alt="WiseOx Logo" /> : <img src={LogoDark} alt="WiseOx Logo" />}
                    </a>

                    <div className="wiseox-badge-links">
                      <a href="https://wiseox.com/legal/terms-of-use" className="meta small" target="_blank" rel="noreferrer">
                        Terms
                      </a>
                      <span>&bull;</span>
                      <a href="https://wiseox.com/legal/privacy" className="meta small" target="_blank" rel="noreferrer">
                        Privacy
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <MascotExcerpts message={showExcerpts} setShowExcerpts={setShowExcerpts} />

        <TrainingModeSidebar show={trainingMode} close={setTrainingMode} messages={messages.filter((m) => !!m.feedback)} />

        {showShareModal && (
          <Modal
            title="Share Conversation"
            isOpen={!!showShareModal}
            close={() => setShowShareModal(false)}
            size="small"
            className="edit-response"
          >
            <div className="input-group">
              <label>Direct link to conversation</label>

              <input
                type="text"
                readOnly
                defaultValue={process.env.REACT_APP_URL_APP + "/chat/" + selectedConversation.shareToken}
              ></input>
            </div>

            <button
              onClick={() =>
                toast.promise(
                  navigator.clipboard.writeText(process.env.REACT_APP_URL_APP + "/chat/" + selectedConversation.shareToken),
                  {
                    success: "Copied to clipboard",
                    error: "Error copying to clipboard",
                  }
                )
              }
            >
              Copy Link
            </button>
          </Modal>
        )}

        {showExportModal && (
          <Modal
            title="Export Conversation Data"
            isOpen={!!showExportModal}
            close={() => setShowExportModal(false)}
            size="small"
            className="edit-response"
          >
            <div className="input-group">
              <label>Select a format to export</label>

              <div className="select">
                <select onChange={(e) => setSelectedDataFormat(e.target.value)}>
                  <option value="json">JSON</option>
                  <option value="csv">CSV</option>
                </select>
              </div>
            </div>

            <button onClick={exportData}>Download</button>
          </Modal>
        )}

        {showArchiveModal && (
          <Modal
            title="Archive Conversation"
            isOpen={!!showArchiveModal}
            close={() => setShowArchiveModal(false)}
            size="small"
            action={
              <button className="danger" onClick={archive}>
                Archive
              </button>
            }
          >
            <div className="input-group">
              <label>Are you sure you want to archive this conversation?</label>
            </div>
          </Modal>
        )}

        {showConversationStatsModal && (
          <Modal
            title="Conversation Stats"
            isOpen={!!showConversationStatsModal}
            close={() => setShowConversationStatsModal(false)}
            size="large"
          >
            <button className="xsmall outline" onClick={refreshConversationStats}>
              Get Latest Stats
              {refreshingConversationStats && <Loader classNames="small"></Loader>}
            </button>

            <h5>Summary</h5>
            <p>{aggregatedStatsSessions?.summary}</p>

            <h5>Success Rating: {aggregatedStatsSessions?.satisfaction}/10</h5>

            <p>
              {aggregatedStatsSessions?.satisfaction === 1 && "This session had a very poor outcome."}
              {aggregatedStatsSessions?.satisfaction === 2 && "This session had a mostly negative outcome."}
              {aggregatedStatsSessions?.satisfaction === 3 && "This session had a somewhat negative outcome."}
              {aggregatedStatsSessions?.satisfaction === 4 && "This session had a slightly negative outcome."}
              {aggregatedStatsSessions?.satisfaction === 5 && "This session had a neutral outcome, but could have been better."}
              {aggregatedStatsSessions?.satisfaction === 6 &&
                "This session had a slightly positive outcome, but could have been better."}
              {aggregatedStatsSessions?.satisfaction === 7 &&
                "This session had a somewhat positive outcome, but could have been better."}
              {aggregatedStatsSessions?.satisfaction === 8 && "This session had a mostly positive outcome."}
              {aggregatedStatsSessions?.satisfaction === 9 && "This session had a very good outcome."}
              {aggregatedStatsSessions?.satisfaction === 10 && "This session had an excellent outcome."}
            </p>

            <h5>Message Count</h5>
            <p>{aggregatedStatsSessions?.prompts} Messages</p>

            <h5>Unknown Answers: {aggregatedStatsSessions?.unknownAnswer?.length}</h5>

            {aggregatedStatsSessions?.unknownAnswer?.map((answer, i) => (
              <div className="blockquote-pair" key={i}>
                <blockquote className="prompt-preview">
                  <span className="meta small">Prompt</span>
                  <p>{answer.prompt.text?.trim()}</p>
                </blockquote>
                <blockquote className="response-preview">
                  <span className="meta small">Response</span>
                  <p>{answer.text?.trim()}</p>
                </blockquote>
              </div>
            ))}
          </Modal>
        )}

        {isShowModalFeedback && (
          <Modal
            title="Edit This Response"
            isOpen={!!isShowModalFeedback}
            close={() => setShowModalFeedback(false)}
            size="large"
            className="edit-response"
            action={
              <button className="action large" onClick={submitFeedback}>
                Submit
              </button>
            }
          >
            <p>
              Please provide a corrected answer. This helps the mascot learn to provide better responses when asked about this topic
              in the future.
            </p>

            <blockquote className="prompt-preview">
              <span className="meta small">Your prompt</span>
              <p>{isShowModalFeedback.promptText}</p>
            </blockquote>

            <div className="md-editor-wrapper" data-color-mode="light">
              <MDEditor
                height="400px"
                value={feedbackValue}
                onChange={(e) => setFeedbackValue(e)}
                autoFocus={true}
                preview="edit"
                hideToolbar="true"
                enableScroll="true"
              />
            </div>

            {errorFeedback && <div className="error">{errorFeedback}</div>}
          </Modal>
        )}

        {mascot.prompt_settings && mascot.prompt_settings.inline_actions && (
          <SelectTextPopup
            containerClass={classPopover}
            onAction={(prompt) => {
              handleSubmitWs(prompt);
            }}
          ></SelectTextPopup>
        )}
      </div>
    </>
  );
}
