import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../hooks/useAuth";
import { servicesClient } from "../services/servicesApi";
import { useMascot } from "../hooks/useMascot";
import TreeViewFilePicker from "./TreeViewFilePicker";
import { useTasks } from "../hooks/useTasks";

function WebFilePicker({ isShown, setShown, websitePath, setShowCrawlerModal, setWebsitePath }) {
  // Normalize url
  let url = websitePath;
  if (!(url.indexOf("http://") === 0 || url.indexOf("https://") === 0)) {
    url = "https://" + url;
  }
  url = url.replace(/\/$/, "");

  const rootDomain = url;
  const { currentUser, currentOrganization, clientId } = useAuth();
  const { mascot } = useMascot();
  const { addTask } = useTasks();
  const [data, setData] = useState([
    {
      name: "root",
      id: 0,
      children: [1],
      parent: null,
      url: "root",
    },
    {
      name: url,
      id: 1,
      children: [],
      parent: 0,
      url: url,
      crawled: false,
      crawling: true,
      isBranch: true,
      isWebPage: true,
    },
  ]);
  const [dataMap, setDataMap] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (isShown) {
      dataMap[rootDomain] = data[1];
      setDataMap(dataMap);
      servicesClient.crawlWebSubpath(mascot._id, url).then((result) => {
        result.ok && isMounted && setData((value) => updateTreeData(value, result.data.links, rootDomain));
      });
    } else {
      setDataMap({});
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [isShown, currentOrganization]);

  const updateTreeData = (tree, newLinks, parentLink) => {
    // Filter new allowed links
    var allowedLinks = newLinks.reduce(function (filtered, l) {
      // Remove js link
      // eslint-disable-next-line
      if (l.startsWith("javascript:") || l.startsWith("mailto:") || l.startsWith("tel:")) return filtered;
      // Normalize www.
      // l = l.replace(/https:\/\/www./, "https://");
      // l = l.replace(/http:\/\/www./, "http://");
      // Convert relative link
      if (l.startsWith("/")) l = rootDomain + l;
      if (l.startsWith(rootDomain)) {
        // Remove hash links
        l = l.split("#")[0];
        // Remove trailing slash
        l = l.replace(/\/$/, "");
        // Remove index.html
        l = l.replace(/\/index.html$/, "");
        // Filter files link
        let fileExt = l.split(".").pop();
        if (fileExt.indexOf("/") !== -1) {
          if (!dataMap[l] || !dataMap[l].isWebPage) {
            // Link is not present
            filtered.push(l);
          }
        }
      }
      return filtered;
    }, []);

    allowedLinks.sort();

    for (let l of allowedLinks) {
      tree = addLink(tree, l);
    }

    dataMap[parentLink].isBranch = false;
    dataMap[parentLink].crawled = true;

    return [...tree];
  };

  const addLink = (tree, l) => {
    let lastSlash = l.lastIndexOf("/");
    let parentPath = l.substring(0, lastSlash);

    if (!dataMap[parentPath]) {
      // Create parent recursively
      let suffix = l.substring(rootDomain.length + 1);
      let paths = suffix.split("/");
      paths.pop();
      let subPath = rootDomain;

      for (let p of paths) {
        let parentSubPath = subPath;
        subPath += "/" + p;
        if (!dataMap[subPath]) {
          // Create subpath
          let newNode = {
            name: p,
            id: tree.length,
            children: [],
            parent: dataMap[parentSubPath].id,
            url: subPath,
            crawled: false,
            isBranch: true,
          };

          tree.push(newNode);
          dataMap[subPath] = newNode;
          dataMap[parentSubPath].children.push(newNode.id);
          dataMap[parentSubPath].isBranch = false;
        }
      }
    }

    // Has parent
    if (!dataMap[l]) {
      if (!dataMap[parentPath]) {
        console.error("Parent page not found for " + parentPath);
      } else {
        let newNode = {
          name: l.substring(lastSlash + 1),
          id: tree.length,
          children: [],
          parent: dataMap[parentPath].id,
          url: l,
          crawled: false,
          isBranch: false,
          isWebPage: true,
        };
        tree.push(newNode);
        dataMap[l] = newNode;

        dataMap[parentPath].children.push(newNode.id);
        dataMap[parentPath].isBranch = false;
      }
    } else {
      dataMap[l].isWebPage = true;
    }

    return tree;
  };

  const selectContent = useCallback(() => {
    let selectedPages = [];
    for (let node of data) {
      if (node.isWebPage && selectedIds.indexOf(node.id) !== -1) {
        selectedPages.push(node.url);

        servicesClient.dataAddWeb(mascot._id, node.url, clientId).then((result) => {
          if (result.ok) {
            addTask(result.data[0]);
          }
        });
      }
    }
    setShown(false);
    setShowCrawlerModal(false);
    setWebsitePath("");
    // eslint-disable-next-line
  }, [mascot, selectedIds, currentUser, setShown]);

  const loadData = async (element) => {
    if (!element.crawled) {
      dataMap[element.url].crawling = true;
      setDataMap(dataMap);
      const result = await servicesClient.crawlWebSubpath(mascot._id, element.url);
      result.ok && setData((value) => updateTreeData(value, result.data.links, element.url));
    }
  };

  const onSelect = useCallback((e) => {
    setSelectedIds(Array.from(e.treeState.selectedIds));
  }, []);

  return (
    <div>
      <TreeViewFilePicker
        data={data}
        disableCheck={true}
        selectedIds={selectedIds}
        onSelect={onSelect}
        defaultExpandedIds={[1]}
        renderItem={(element) => (
          <span className="name">
            {element.isWebPage ? (
              <>
                <a href={element.url} target="_blank" rel="noreferrer">
                  {element.name}
                </a>

                {!element.crawled && (
                  <>
                    {dataMap[element.url] && dataMap[element.url].crawling ? (
                      <button className="btn-scan xxsmall" disabled={true}>
                        Looking for subpages
                      </button>
                    ) : (
                      <button className="btn-scan xxsmall" onClick={() => loadData(element)}>
                        Check for subpages
                      </button>
                    )}
                  </>
                )}
              </>
            ) : (
              element.name
            )}
          </span>
        )}
      />

      <div className="modal-actions">
        <button className="action" disabled={selectedIds.length === 0} onClick={() => selectContent()}>
          Confirm
        </button>
        <button onClick={() => setShown(false)}>Change URL</button>
      </div>
    </div>
  );
}

export default WebFilePicker;
