import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MascotImage from "./MascotImage";
import classNames from "classnames";
import Popover from "./Popover";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { client } from "../services/api";
import { servicesClient } from "../services/servicesApi";
import Modal from "./Modal";
import Loader from "./Loader";

export function MascotList({ mascots, fetchMascots }) {
  const [showOptionsPopover, setShowOptionsPopover] = useState({});
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const history = useHistory();
  const [disabledText, setDisabled] = useState("");
  const archiveInputRef = useRef();

  const archive = async () => {
    if (archiveInputRef.current?.value === "archive") {
      await client.archiveMascot(showArchiveModal);
    }
    setShowArchiveModal(false);
    toggleShowOptionsPopover(showArchiveModal);
    fetchMascots();
  };

  const toggleShowOptionsPopover = (mascotId) => {
    showOptionsPopover[mascotId] = !!!showOptionsPopover[mascotId];
    setShowOptionsPopover({ ...showOptionsPopover });
  };

  const cloneMascot = async (mascot) => {
    const tempMascot = {
      _id: `temp-${Date.now()}`,
      name: `${mascot.name} (duplicate)`,
      description: mascot.description,
      image: mascot.image,
      pending: true,
    };
    mascots.push(tempMascot);
    const response = await servicesClient.duplicateMascot(mascot._id);
    if (!response.ok) {
      toast.error(response.data?.error || "Failed to duplicate mascot.");
    }
    fetchMascots();
  };

  const goTo = (url) => {
    history.push(url);
  };

  return (
    <div className="mascot-list">
      {mascots
        .slice(0)
        .reverse()
        .map((mascot) => {
          return (
            <div className={classNames("mascot-list-item", !mascot.image && "empty")} key={mascot._id}>
              {mascot.pending ?
                <div className="mascot-list-item-info duplicating">
                  <MascotImage mascot={mascot}></MascotImage>

                  <h2>{mascot.name}</h2>
                  <p>{mascot.description}</p>

                  <span className="meta light loading">Creating duplicate <Loader classNames="dark" /></span>
                </div>
                :
                <>
                  <div className="icon-btn light" onClick={() => toggleShowOptionsPopover(mascot._id)}>
                    <i className="icon-ellipses"></i>

                    {showOptionsPopover[mascot._id] && (
                      <Popover close={() => toggleShowOptionsPopover(mascot._id)} className="right">
                        <div className="popover-item" onClick={() => goTo(`/mascot/${mascot._id}/chat`)}>
                          <i className="icon-mascot"></i>
                          View Mascot
                        </div>
                        <div className="popover-item" onClick={() => goTo(`/mascot/${mascot._id}/edit/info`)}>
                          <i className="icon-edit"></i>
                          Edit Mascot
                        </div>

                        <div className="popover-item" onClick={() => cloneMascot(mascot)}>
                          <i className="icon-copy"></i>
                          Make Duplicate
                        </div>

                        <div className="popover-item danger" onClick={() => setShowArchiveModal(mascot._id)}>
                          <i className="icon-warn"></i>
                          Archive
                        </div>
                      </Popover>
                    )}
                  </div>

                  <Link to={`/mascot/${mascot._id}/chat`}>
                    <div className="mascot-list-item-info">
                      <MascotImage mascot={mascot}></MascotImage>

                      <h2>{mascot.name}</h2>
                      <p>{mascot.description}</p>

                      <div className="stats">
                        <div className="stat-label">{mascot.countSessions || "0"} Sessions</div>
                        <div className="stat-label">
                          {mascot.countUsers || "0"} {mascot.countUsers === 1 ? "User" : "Users"}
                        </div>
                      </div>
                    </div>

                    {mascot.image ? (
                      <div className="mascot-list-item-bg" style={{ backgroundImage: `url(${mascot.image})` }}></div>
                    ) : (
                      <div className="mascot-list-item-bg empty"></div>
                    )}
                  </Link>
                </>
              }
            </div>
          );
        })}

      <Modal
        title="Archive Mascot"
        size="small"
        isOpen={!!showArchiveModal}
        close={() => setShowArchiveModal(false)}
        action={
          <button onClick={archive} className="danger large wide" disabled={disabledText !== "archive"}>
            Archive
          </button>
        }
      >
        <p>Archiving a mascot will make it publicly unavailable and inaccessible in your account.</p>
        <p>If you want to unarchive this mascot at any point, please contact us.</p>

        <div className="input-group">
          <label>Enter “archive” to submit</label>
          <input
            onKeyDown={(e) => {
              if (e.code === "Space") e.preventDefault();
            }}
            onChange={(e) => setDisabled(e.target.value)}
            ref={archiveInputRef}
            type="text"
            defaultValue=""
            placeholder="archive"
          />
        </div>
      </Modal>
    </div>
  );
}
