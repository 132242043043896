import React, { useState, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import MascotImage from "../components/MascotImage";
import { useConversations } from "../hooks/useConversations.js";
import { useAuth } from "../hooks/useAuth";
import { client } from "../services/api";
import classNames from "classnames";

const initialFormState = {
  firstName: "",
  lastName: "",
  email: "",
};

const sheetSchema = Yup.object().shape({
  firstName: Yup.string().trim().required("First Name required"),
  lastName: Yup.string().trim().required("Last Name required"),
  email: Yup.string()
    .required("Email Address is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please enter a valid email address"),
});

export default function MascotChatAuth() {
  const { mascot } = useConversations();
  const { guestLogIn, guest, setGuest } = useAuth();
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [verify, setVerify] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  let { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: initialFormState,
    validationSchema: sheetSchema,
    onSubmit: handleSubmission,
  });

  async function handleSubmission(values) {
    setError(false);
    setSubmitting(true);
    const registerResult = await client.guestRegister({ ...values, mascotId: mascot._id });
    if (registerResult.ok) {
      if (registerResult.data.verify) {
        setGuest({
          _id: registerResult.data.guestId,
          firstName: registerResult.data.firstName?.trim(),
          lastName: registerResult.data.lastName?.trim(),
          email: registerResult.data.email,
        });
        setVerify(true);
      } else if (registerResult.data.guestId) {
        guestLogIn();
      }
    } else {
      setError(registerResult.data?.message || registerResult.problem);
    }
    setSubmitting(false);
  }

  const _handleKeyDown = useCallback((e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  }, [handleSubmit]);

  const handleSubmitVerification = useCallback(async () => {
    setVerificationError(false);
    if (verificationCode === '') {
      setVerificationError('Verification Code required');
      return;
    }
    const verificationResult = await client.guestVerify({ guestId: guest?._id, verificationCode });
    if (verificationResult.ok) {
      guestLogIn();
    } else {
      setVerificationError(verificationResult.data.message);
    }
  }, [guest, guestLogIn, verificationCode]);

  const _handleVerificationKeyDown = useCallback((e) => {
    if (e.key === "Enter") {
      handleSubmitVerification();
    }
  }, [handleSubmitVerification]);

  return (
    <div
      className={classNames(
        'guest-auth-wrapper',
        mascot?.theme?.darkTheme ? "dark-theme" : "light-theme"
      )}
      style={{ fontFamily: mascot.theme && mascot.theme.fontFamily }}
    >
      {/* Load Libs */}
      <link rel="preconnect" href="https://fonts.gstatic.com" />

      {mascot.theme && mascot.theme.fontFamily && (
        <link
          rel="stylesheet"
          href={`https://fonts.googleapis.com/css2?family=${mascot.theme.fontFamily.replace(
            /\s/g,
            "+"
          )}:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap`}
        />
      )}

      <div className="signin-form-wrapper">
        <MascotImage mascot={mascot._id && mascot}></MascotImage>

        <div className="signin-form">
          <h2>{mascot?.name}</h2>
          <p>{mascot?.description}</p>

          {verify ? (
            <div key="verification">
              <span className="meta">
                Please enter the code sent to your email
              </span>
              <div className="input-group">
                <input
                  type="text"
                  className="center mono"
                  name="verificationCode"
                  required
                  placeholder="Enter Code"
                  tabIndex="1"
                  autoComplete="do-not-autofill"
                  onKeyDown={_handleVerificationKeyDown}
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
              </div>

              {verificationError ? <div className="error">{verificationError}</div> : null}

              <button 
                className="large"
                style={{ backgroundColor: mascot.theme && mascot.theme.brandColor }}
                onClick={handleSubmitVerification} 
                tabIndex="2"
              >
                Continue
              </button>
            </div>
          ) : (
            <div key="signin">
              <span className="meta">
                Please sign in to access this mascot
              </span>
              <div className="input-group">
                <input
                  type="text"
                  name="firstName"
                  required
                  value={values.firstName}
                  placeholder="First Name"
                  onChange={handleChange}
                  tabIndex="1"
                  autoComplete="do-not-autofill"
                />
                {errors.firstName && touched.firstName ? <div className="error">{errors.firstName}</div> : null}
              </div>

              <div className="input-group">
                <input
                  type="text"
                  name="lastName"
                  required
                  value={values.lastName}
                  placeholder="Last Name"
                  onChange={handleChange}
                  tabIndex="2"
                  autoComplete="off"
                />
                {errors.lastName && touched.lastName ? <div className="error">{errors.lastName}</div> : null}
              </div>

              <div className="input-group">
                <input
                  type="email"
                  name="email"
                  required
                  placeholder="Email address"
                  value={values.email}
                  onChange={handleChange}
                  tabIndex="3"
                  onKeyDown={_handleKeyDown}
                />
                {errors.email && touched.email ? <div className="error">{errors.email}</div> : null}
              </div>

              {error && <div className="error">{error}</div>}

              <button
                disabled={submitting}
                className="large" 
                style={{ backgroundColor: mascot.theme && mascot.theme.brandColor }}
                onClick={handleSubmit} 
                tabIndex="4"
              >
                Continue
              </button>
            </div>
          )}
        </div>

        <div className="terms-links">
          <span className="meta small">By signing in, you agree to the WiseOx <a href="https://wiseox.com/legal/terms-of-service" className="meta" target="_blank" rel="noreferrer">Terms of Service</a></span>
        </div>
      </div>
    </div>
  );
}
