import React from "react";

export default function TrainingModeSidebar({ show, close, messages }) {
  return show ? (
    <div className="drawer mascot-training">
      {/* <div className="icon-btn" onClick={() => close(false)}>
        <i className="icon-close"></i>
      </div> */}

      <h5>Tuning Mode</h5>
      <p className="small">You can tune a mascot to improve its response quality by ranking responses for a chat.</p>
      <p className="small">Tuning Mode can be used at any point during a chat, as well as previous chats.</p>

      {messages.length === 0 ? (
        <div className="empty">
          <span className="meta">No Rankings Yet</span>
        </div>
      ) : (
        <div>
          <div className="meta small">
            {messages.length} Response{messages.length > 1 && "s"} Ranked
          </div>

          <div className="progress-bar">
            <progress value={messages.filter((m) => m.feedback === "positive").length} max={messages.length}></progress>
          </div>

          <div className="meta small">Ranked Responses</div>

          <div className="rankings">
            {messages.map((m, k) => (
              <blockquote className="ranked" key={k}>
                {m.feedback === "positive" && <i className="icon-thumbs-up-solid"></i>}
                {m.feedback === "negative" && <i className="icon-thumbs-down-solid"></i>}
                <span className="truncate">{m.text?.slice(0, 60)}</span>
              </blockquote>
            ))}
          </div>
        </div>
      )}
    </div>
  ) : null;
}
