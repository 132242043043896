import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { Pie } from "react-chartjs-2";
import classNames from "classnames";
import Loader from "../Loader";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

export default function PieChart({ data: inputData, title, className, label }) {
  const [data, setData] = useState(inputData);

  const options = {
    responsive: true,
    borderWidth: 0,
    spacing: 1,
    cutout: "30%",
    radius: 80,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          color: "#f7f7f7",
          pointStyle: "rectRounded",
          padding: 18,
          font: {
            family: "Lexend",
          },
        },
      },
    },
  };

  useEffect(() => {
    if (inputData) {
      setData({
        labels: inputData.map((d) => d.label + " - " + parseInt(d.perc) + "%"),
        datasets: [
          {
            data: inputData.map((d) => d.count),
            backgroundColor: inputData.map((d) => d.color),
            hoverOffset: 0,
          },
        ],
      });
    }
  }, [inputData]);

  return (
    <div className={classNames("chart", "pie-chart", className && className)}>
      <h4>{title}</h4>

      {data ? (
        data.datasets && data.datasets[0].data.reduce((acc, curr) => acc + curr, 0) > 0 ? (
          <Pie options={options} data={data} />
        ) : (
          <h5 className="no-data">No data yet</h5>
        )
      ) : (
        <Loader classNames="dark large"></Loader>
      )}
    </div>
  );
}
